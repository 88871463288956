import React from "react";
import { useTranslation } from "react-i18next";
import "../../styles/PT-BR/footer.css";
import logoFooter from "../../img/logo-mundodigital_footer.png";
import logoWhatsapp from "../../img/whatsapp.svg";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation(); // Desestruturando a função de tradução

  return (
    <footer>
      <div className="bloco1">
        <p className="textoDefault">{t('contact_us')}</p>
        <br />
        <a href="tel:551149330335">
          <h2 className="textoNegrito">+55 (11) 4933 0335</h2>
        </a>
        <a href="mailto:contato@mundolivre.digital">
          <h2 className="textoNegrito">contato@mundolivre.digital</h2>
        </a>
        <br />
        <Link to="/politicas-e-termos" className="textoDefault">
          {t('privacy_policy')}
        </Link>
      </div>
      <div className="bloco2">
        <p className="textoDefault">{t('instagram')}</p>
        <a href="https://www.instagram.com/mundolivredigital/">
          <h2 className="textoNegrito">@mundolivredigital</h2>
        </a>
        <br />
        <p className="textoDefault">{t('linkedin')}</p>
        <a href="https://www.linkedin.com/company/mundolivredigital/">
          <h2 className="textoNegrito">Mundo Livre Digital</h2>
        </a>
      </div>
      <div className="img-footer">
      <img src={logoFooter} alt="Logo do rodapé" />
      </div>
      <div className="whatsapp-button">
        <a href="https://wa.me/551149330335" target="_blank" rel="noopener noreferrer">
          <img src={logoWhatsapp} alt="WhatsApp" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
