import React from "react";
import bannerHumanos from "../../img/banner_humanos.jpg";
import CardHumano from "./cardHumans";
import ritaImagem from "../../img/RitaFoto.jpg";
import caiqueImagem from "../../img/caiqueFoto.jpg";
import guilhermeImagem from "../../img/guilhermeFoto.jpg";
import thaisImagem from "../../img/thaisFoto.jpg";
import renanImagem from "../../img/renanFoto.jpg";
import "../../styles/PT-BR/humanos.css";
import CarrosselHumanos from "./carrosselTextoHumanos";
import { useTranslation } from "react-i18next"; // Importação do i18n

const HumanosComponentes = () => {

  const { t } = useTranslation(); // Hook do i18n

  const slides = [
    { titulo: t("tituloHumanosTexto1"), conteudo: t("conteudoHumanosTexto1") },
    { titulo: t("tituloHumanosTexto2"), conteudo: t("conteudoHumanosTexto2") },
    { titulo: t("tituloHumanosTexto3"), conteudo: t("conteudoHumanosTexto3") },
    { titulo: t("tituloHumanosTexto4"), conteudo: t("conteudoHumanosTexto4") },
  ];


  return (
    <section className="humanosContainer">
      <div className="sessao1">
        <div className="box1">
          <h1 className="humanosTitulo">{t("humanosTitulo")}</h1>
          <p className="humanosParagrafo1">
            {t("humanosParagrafo1")}
            <br />
            <br />
          </p>
        </div>
        <div className="box2">
          <strong className="list-titulo">{t("humanosParagrafo2")}</strong>
          <ul className="list-beliefs">
            <li>
              <h2>{t("tituloHumanosTexto1")}</h2>
              <p>{t("conteudoHumanosTexto1")}</p>
            </li>
            <li>
              <h2>{t("tituloHumanosTexto2")}</h2>
              <p>{t("conteudoHumanosTexto2")}</p>
            </li>
            <li>
              <h2>{t("tituloHumanosTexto4")}</h2>
              <p>{t("conteudoHumanosTexto4")}</p>
            </li>
          </ul>
        </div>
        <div className="box3">
          <img src={bannerHumanos} alt="Banner Humanos" />
        </div>
        <div className="box4">
          <ul className="list-beliefs mobile-reorder">
            <li>
              <h2>{t("tituloHumanosTexto3")}</h2>
              <p>{t("conteudoHumanosTexto3")}</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="sessao3">
        <h1 className="humanos-subtitulo">
          {t("humanosTituloCards")}
        </h1>
      <div className="grid-card">
          <CardHumano

            nome="Rita D´Andrea"

            cargo={t("cargoRita")}

            imagem={ritaImagem}

            linkedin="https://www.linkedin.com/in/ritad1/"

            bio={t("bioRita")}
          />
          <CardHumano

            nome="Caique Alves"

            cargo="Tech Lead"

            imagem={caiqueImagem}

            linkedin="https://www.linkedin.com/in/caique-pereira-284076176/"

            bio={t("bioCaique")}
          />
          <CardHumano

            nome="Thais Arruda"

            cargo={t("cargoThais")}

            imagem={thaisImagem}

            linkedin="https://www.linkedin.com/in/thais-arruda-sagardoy-013b03160/"

            bio={t("bioThais")}
          />
          <CardHumano

            nome="Guilherme Soares"

            cargo="Tech Lead"

            imagem={guilhermeImagem}

            linkedin="https://www.linkedin.com/in/guilherme-felipe1/"

            bio={t("bioGuilherme")}
          />
          
          <CardHumano

            nome="Renan Medeiros"

            cargo={t("cargoRenan")}

            imagem={renanImagem}

            linkedin="https://www.linkedin.com/in/renan-medeiros-/"

            bio={t("bioRenan")}
          />
        </div>
    </div>
    </section>
  );
};

export default HumanosComponentes;
