import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import '../../styles/PT-BR/cardHumanos.css';
import logoLinkedIn from "../../img/linkedInLogo.png";

const CardHumano = ({ nome, cargo, imagem, linkedin, bio }) => {
    const { t } = useTranslation();
    const [mostrarModal, setMostrarModal] = useState(false);

    const toggleModal = () => {
        setMostrarModal(!mostrarModal);
    };
  
    return (
        <div className='grid-card'>  
            <div className="card-humano">
                <img src={imagem} alt={`Foto de ${nome}`} className="profile-image" />
                <div className="card-content">
                    <h2 className='card-name'>{nome}</h2>
                    <p className="cargo">{cargo}</p>
                    <a href={linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-btn">
                        <img src={logoLinkedIn} alt="LinkedIn" />
                    </a>
                    <button className="saiba-mais-btn" onClick={toggleModal}>
                        {t("card_humanos_btn")}
                    </button>
                </div>
            </div>

            {mostrarModal && (
                <div className="modal-overlay" onClick={toggleModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-modal" onClick={toggleModal}>X</button>
                        <h2>{nome}</h2>
                        <p>{bio}</p>
                    </div>
                </div>
            )}
        </div>
    );
  };
  
  export default CardHumano;