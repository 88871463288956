import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Carrega o idioma salvo no localStorage ou usa 'pt' como padrão
const savedLanguage = localStorage.getItem('language') || 'pt';
const resources = {
  pt: {
    translation: {
      home: "HOME",
      about_us: "SOBRE NÓS",
      once_upon_a_time: "ERA UMA VEZ",
      humans: "HUMANOS",
      expertise: "EXPERTIZE",
      specialization: "ESPECIALIZAÇÃO",
      partners: "PARCEIROS",
      clients: "CLIENTES",
      mundo_faz: "MUNDOFAZ",
      mundo_agenda: "MUNDOAGENDA",
      mundo_atende: "MUNDOATENDE",
      mundo_dispara: "MUNDODISPARA",
      mundo_academy: "MUNDOACADEMY",
      mundo_catalogue: "MUNDOCATALOGUE",
      contact: "CONTATO",
      work_with_us: "TRABALHE CONOSCO",
      contact_us: "Fale com a gente!",
      privacy_policy: "Política de privacidade",
      instagram: "Instagram",
      linkedin: "Linkedin",
      once_upon_a_time_title: "Era uma vez",

      once_upon_a_time_paragraph_1:
        "…a Mundo Livre Digital, uma empresa fundada na nuvem, que nasceu do sonho (ou delírio ☺) de uma mulher, que acreditava ser possível fazer com que o mundo fosse livre e digital, democratizando o acesso das mais avançadas tecnologias de informação a todo o tamanho e tipo de empresa.",

      once_upon_a_time_paragraph_2:
        "…uma empresa que era um espaço para que seus colaboradores pudessem desenvolver seu potencial humano (todos temos e somos únicos), gerando valor para o time, para os seus Clientes e Parceiros.",

      once_upon_a_time_paragraph_3:
        "A Mundo Livre Digital foi criada com o objetivo de ajudar os Humanos e Humanas a se livrarem das atividades mundanas e repetitivas, para ter tempo de pensar, criar e incorporar inovações aos seus negócios.",

      once_upon_a_time_paragraph_4:
        "Nós compartilhamos dos objetivos do movimento Humane Tech, que tem como missão “mudar a tecnologia para um futuro mais humano que apoie nosso bem-estar, funcionamento democrático e ambiente de informações compartilhadas.”",

      woman_alt_text: "Mulher com um celular na mão",
      group_people_alt_text: "Banner grupo de pessoas",
      wilson_quote:
        "“O verdadeiro problema da humanidade é este: temos emoções paleolíticas, instituições medievais e tecnologia divina.”",

      especializacao_title: "Especialização",

      especializacao_paragrafo_1:
        "Nos especializamos em oferecer serviços de atendimento ao cliente que solucionam problemas por vertical de indústria.",

      especializacao_paragrafo_2:
        "Usamos Plataformas de Atendimento e Inteligência Artificial oferecidas “as-a-service” para:",

      especializacao_lista_item_1:
        "Massificar a personalização e fazer seus clientes se sentirem especiais;",

      especializacao_lista_item_2:
        "Ser empático(a), criando atendimentos com contexto, onde o(a) cliente se sente compreendido(a) e acolhido(a);",

      especializacao_lista_item_3:
        "Oferecer atendimentos objetivos, sem desperdício de tempo, indo direto ao ponto, sem repetição de informações.",

      parceiros_title: "Parceiros",

      parceiros_paragrafo:
        " Contamos com a parceria de empresas renomadas e conhecidas por prezarem a inovação e acreditarem que a diversidade é um fator importante para a evolução do ser humano.",
      mundo_atende_title: "MundoAtende",
      mundo_atende_subtitle:
        "Eleve o padrão de atendimento da sua empresa, otimizando o tempo dos seus colaboradores e atendendo de forma diferenciada os seus clientes.",
      mundo_atende_description1:
        "MundoAtende é uma ferramenta de atendimento via WhatsApp, proporcionando aos seus clientes um atendimento personalizado, ágil e eficiente. Além disso, oferece aos seus atendentes um ambiente de trabalho otimizado e intuitivo.",
      mundo_atende_how_it_works: "Como Funciona?",
      mundo_atende_description2:
        "Com a solução de atendimento MundoAtende você oferece um atendimento ao público com o mais alto nível de segurança e praticidade!",
      mundo_atende_description3:
        "Crie menus personalizados para que os clientes sejam encaminhados por motivo de contato para o funcionário mais bem qualificado para atendê-los.",
      mundo_atende_description4:
        "Se necessário, transfira a interação do cliente para outras áreas da empresa de maneira rápida e eficiente, garantindo agilidade no atendimento.",
      mundo_atende_description5:
        "Facilite a troca de informações, permitindo o envio de anexos entre a empresa e os clientes, e torne a comunicação mais descontraída utilizando emojis.",
      mundo_atende_image_alt: "Banner mundo atende",
      clientes_title: "Clientes",
      clientes_paragrafo: "Conheça alguns dos nossos clientes:",

      "mundo_agenda_title": "MundoAgenda",
      "mundo_agenda_subtitle": "Agendar e confirmar pelo WhatsApp nunca foi tão fácil e eficiente!",
      "mundo_agenda_paragrafo_1": "A MundoAgenda é uma ferramenta de inteligência artificial conectada à ferramenta MundoAtende, via WhatsApp. Com ela é possível agendar, reagendar e cancelar compromissos automaticamente no seu sistema de agendamento, mantendo-o sempre atualizado.",
      "mundo_agenda_paragrafo_2": "A principal vantagem para a sua empresa é reduzir a quantidade de atendentes e gastos com infraestrutura, uma vez que o agendamento, a confirmação, o cancelamento e o reagendamento são feitos pela inteligência artificial.",
      "mundo_agenda_link": "Saiba mais",

      //MUNDO DISPARA ------------------------

      "mundo_dispara_title": "MundoDispara",
      "mundo_dispara_paragrafo_1": "O MundoDispara é uma ferramenta omnichannel de contatos interativos de texto ou de voz, fazendo com que os clientes falem com a sua empresa de forma ágil e direta.",

      "MD_telemedicina_title": "Telemedicina",
      "MD_telemedicina_lista_1": "O paciente ao entrar na sala de videoconferência percebe que o médico está atrasado e se afasta do celular ou do computador;",
      "MD_telemedicina_lista_2": "Quando médico entra na sala o paciente está conectado, mas não está presente;",
      "MD_telemedicina_lista_3": "MundoDispara faz uma chamada de voz ao paciente;",
      "MD_telemedicina_lista_4": "Ao atender a chamada paciente ouve mensagem pedindo que retorne à sala de consulta e tem a opção de falar com um humano.",

      "MD_operadoraSaude_title": "Operadora de Saúde",
      "MD_operadoraSaude_lista_1": "Todas as segundas-feiras o HealthCloud gera uma lista de pacientes que passaram pelo Pronto-Socorro no final de semana e envia base ao MundoDispara;",
      "MD_operadoraSaude_lista_2": "Ao atender a ligação são validados os dados de contato;",
      "MD_operadoraSaude_lista_3": "A seguir pergunta se o paciente ainda sente os sintomas do motivo da visita ao Pronto-Socorro, e se deseja ser atendido pela Enfermagem;",
      "MD_operadoraSaude_lista_4": "Caso positivo a ligação é transferida para enfermeiros.",

      "MD_hospital_title": "Hospital",
      "MD_hospital_lista_1": "Todo dia é gerada uma lista de potenciais pacientes que terão alta no dia seguinte;",
      "MD_hospital_lista_2": "Esta base é carregada no MundoDispara pela própria enfermagem para envio mensagem ao acompanhante do paciente via WhatsApp, informando sobre os procedimentos de alta hospitalar;",
      "MD_hospital_lista_3": "Caso tenha dúvida o acompanhante pode esclarecê-la teclando com humano via WhatsApp.",

      "MD_marketplace_title": "Marketplace de Restaurantes",
      "MD_marketplace_lista_1": "O Marketplace quer avisar os donos de restaurante de uma nova localidade que já é possível se cadastrar na plataforma;",
      "MD_marketplace_lista_2": "MundoDispara,  a partir de uma base de dados envia a mensagem com o convite;",
      "MD_marketplace_lista_3": "Ao responder são validadas as informações do restaurante;",
      "MD_marketplace_lista_4": "Em seguida é enviada mensagem anunciando que o cadastro no Marketplace para esta região já está disponível;",
      "MD_marketplace_lista_5": "Caso o proprietário tenha interesse em se cadastrar é enviado um link de acesso ao formulário no no Marketplace.",
      "contatoTitulo": "Entre em contato",
      "contatoTelefone": "Telefone",

      "mapaMLD": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14627.481807588374!2d-46.6566002!3d-23.5730958!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce574dcdd00001%3A0xbf3aa754aebdd392!2sMundo%20Livre%20Digital!5e0!3m2!1spt-BR!2sbr!4v1724271384946!5m2!1spt-BR!2sbr",

      "titulo_form": "Formulário de contato",
      "botao_form": "Deixe seu contato",
      "nome_form": "Nome",
      "telefone_form": "Telefone",
      "enviar_form": "Enviar",
      "email_form": "E-mail",
      "termo_servico_1": "Ao clicar em enviar, você concorda com nosso",
      "termo_servico_2": "Termos de Serviço",
      "termo_servico_3": "e que você leu nossa",
      "termo_servico_4": "Política de Privacidade",
      "autorizacao_envio_messagem": " Autorizo envio de notificação por SMS/Mensagem Promocional/Mensagem Informativa",

      /* ------------------- PÁGINA POLITICAS PRIVACIDADE ----------------*/
      "titulo_principal_politicas": "Políticas e Termos",
      "titulo_principal_politicas2": "Politicas de Privacidade",
      "titulo_dropdown_politicas": "Política de Privacidade",
      "politicas_titulo_1": "1. Dados que podem ser coletados em razão do uso do site",
      "paragrafo_politicas_1": "Em razão da utilização de um dos meios indicados para contato indicado no Site, podem ser coletados dados cadastrais, tais como nome, e-mail, telefone, entre outras informações necessárias para a continuidade do atendimento de sua demanda.",
      "politicas_titulo_2": "2. Link para sites de terceiros",
      "paragrafo_politicas_2": "O site contém links para direcionamento de site de terceiros para fins de candidatura para oportunidade de trabalho na Mundo Livre Digital. Este serviço é disponibilidade pela empresa REACHR e, caso você acesso a página ou candidata-se a uma oportunidade por meio deste link, você concorda com os termos de uso e política de privacidade da empresa parceira disponíveis nos links https://www.reachr.com.br/termos-de-uso/ e https://www.reachr.com.br/politica-de-privacidade/, as quais recomendamos uma atenta leitura para que você possa se informar com é realizado o tratamento de seus dados pessoais. A Mundo Livre Digital não se responsabiliza pelo site da parceira e seus conteúdos.",
      "politicas_titulo_3": "3. Cookies Utilizados no Site",
      "paragrafo_politicas_3_1": "A MUNDO LIVRE DIGITAL utiliza de cookies com a finalidade de aprimorar a experiência do usuário no site. Os cookies podem armazenar informações do usuário que acessa o site, tais como: localização, acessos, origem do acesso, estatísticas, endereço; clientes, países, endereço de IP, dispositivo utilizado no acesso. Os cookies, que são pequenos arquivos de texto armazenados no seu disco rígido e atribuídos ao navegador usado que enviam informações a quem criou, utilizados no site da MUNDO LIVRE DIGITAL desempenham funções: (a) necessárias para permitir a navegação no site (cookies funcionais); (b) melhorar a experiência do usuário (cookies rastreadores); e (b) coletar de dados estatísticos (cookies analíticos).Os cookies utilizados no site são temporários e persistentes.Os primeiros são excluídos automaticamente após o fechamento do navegador, podendo armazenar ID de sessão para facilitar o seu reconhecimento em uma visita futura.Já os cookies persistentes permanecem em seu dispositivo por um tempo considerável ou até que você os delete, sendo possível excluir os cookies nas configurações de segurança do seu navegador a qualquer momento.A não aceitação de cookies de terceiros ou todos os cookies pode impedir ou prejudicar a navegação e uso do site.",
      "paragrafo_politicas_3_2": "Os cookies utilizados no site são temporários e persistentes. Os primeiros são excluídos automaticamente após o fechamento do navegador, podendo armazenar ID de sessão para facilitar o seu reconhecimento em uma visita futura. Já os cookies persistentes permanecem em seu dispositivo por um tempo considerável ou até que você os delete, sendo possível excluir os cookies nas configurações de segurança do seu navegador a qualquer momento.",
      "paragrafo_politicas_3_3": " A não aceitação de cookies de terceiros ou todos os cookies pode impedir ou prejudicar a navegação e uso do site.",
      "paragrafo_politicas_3_4": "Os Cookie de terceiros utilizados no site são:",
      "lista_politicas_3_1": "Google Analytics: (cookies Rastreadores) coleta dados estatísticos a fim de medir monitorar o desempenho do site, permitindo monitorar o perfil do usuário do site, páginas mais acessadas, cidades, países, IP e hora de acesso ao site;",
      "lista_politicas_3_2": "Google Tag Manager, destinado a administração de tags de sites por meio de uma interface de usuário;",
      "lista_politicas_3_3": "Google Maps: Informa ao colaborador a localização da sede da empresa.",
      "paragrafo_politicas_3_5_1": "Observação: A veracidade e precisão dos dados inseridos nos campos existentes no site são de exclusiva responsabilidade do usuário, não se responsabilizando a MUNDO LIVRE DIGITAL pela veracidade ou precisão dos dados ou informações prestadas pelos usuários. Poderá o usuário solicitar a correção de seus dados constante do site por meio do e - mail ao endereço",
      "paragrafo_politicas_3_5_2": "indicando no campo assunto o tema Privacidade.",
      "paragrafo_politicas_3_6": "Ressaltamos que a MUNDO LIVRE DIGITAL não realiza venda ou comercialização de dados pessoais coletados, sendo os dados pessoais coletados utilizados unicamente nas finalidades para as quais foram coletadas,o que, como acima referido, não impede o compartilhamento de dados com terceiros para o cumprimento das finalidades da MUNDO LIVRE DIGITAL.",
      "politicas_titulo_4": "4. Propriedade Intelectual",
      "paragrafo_politicas_4": "Todo o conteúdo disponibilidade no site, informações, notícias, imagens, marcas, independentemente da forma de apresentação, é de exclusiva propriedade ou licença da MUNDO LIVRE DIGITAL.Assim, é vedado ao usuário do site: ",
      "lista_politicas_4_1": "Reproduzir, republicar, duplicar ou copiar qualquer conteúdo disponível no site, especialmente às marcas e produtos detidos pela Mundo Livre Digital; ",
      "lista_politicas_4_2": "Vender, alugar, sublicenciar e/ou de outra forma comercializar qualquer conteúdo do site Mundo Livre Digital, sem a prévia contratação ou autorização; ",
      "lista_politicas_4_3": "Executar e / ou exibir publicamente qualquer conteúdo de Mundo Livre Digital, sem prévia autorização da Mundo Livre Digital;",
      "lista_politicas_4_4": "Usar o site de forma que possa danificar e/ou afetar o acesso ao site ou a nossa marca; ",
      "lista_politicas_4_5": "Usar este site contrário às regras, leis e regulamentos relevantes do Brasil e/ou de seu país de residência;",
      "lista_politicas_4_6": "Realizar mineração de dados ou qualquer outra atividade semelhante relacionada a este site, ou durante o uso deste site; e",
      "lista_politicas_4_7": "Usar este site para desenvolver qualquer forma de publicidade ou marketing empresarial, sem prévia autorização da Mundo Livre Digital. ",
      "politicas_titulo_5": "5. Forma de Contato",
      "paragrafo_politicas_5_1": "Para eliminar eventuais dúvidas sobre estes Termo de Uso você pode encaminhar e-mail para:",
      "paragrafo_politicas_5_2": "indicando no campo assunto o tema “Privacidade”.",
      "politicas_titulo_6": "6. Disposições Gerais",
      "paragrafo_politicas_6_1": "Informações adicionais podem ser verificadas em nossa política de privacidade.",
      "paragrafo_politicas_6_2": "O site pode passar por períodos de indisponibilidades por diversos motivos, tais como atualizações, alterações, manutenções, etc, assim como poderá ser retirado do ar a exclusivo critério e a qualquer tempo pela MUNDO LIVRE DIGITAL. O descumprimento de qualquer cláusula deste Termo de Uso poderá ocasionar a sua responsabilização nas esferas cível e penal, quando aplicável e de levando em consideração a natureza do descumprimento O presente Termo de Uso foi aprovada na data de 23/10/2024 e permanecerá em vigor até posterior revogação ou alteração pela MUNDO LIVRE DIGITAL, que se reserva no direito de realizar, a qualquer momento,alterações em sua política de privacidade, sendo que a versão atualizada será disponibilizada no site mundolivre.digital O presente Termo de Uso será regido, interpretado e executado de acordo com as leis do Brasil, sendo eleito o Foro da Comarca de São Paulo- SP para dirimir qualquer dúvida decorrente deste instrumento.",
      "link_pagina_contato_politicas": " Página de contato",

      "titulo_dropdown_termos": "Termos de Serviço",
      "paragrafo_termos_1": "Reafirmando o seu compromisso com a integridade e proteção da privacidade do usuário do site e de seus serviços, a MUNDO LIVRE DIGITAL elaborou a presente Termo de Uso do Site, em que se compromete a respeitar a Lei nº. 13.709/2018, Lei Geral de Proteção de Dados.",
      "paragrafo_termos_2": "Este Termo de Uso estabelece as condições de utilização do Site e acesso às suas funcionalidades, que incluem o acesso a informações dos produtos oferecidos por nós, informações sobre nossa equipe, acesso aos canais de atendimento Mundo Livre Digital e cadastramento para oportunidades de trabalho caso você tenha interesse em integrar o time Mundo Livre Digital.",
      "paragrafo_termos_3": "Enquanto visitante do site, você reconhece e declara ter lido e aceito as regras descritas nesse documento, conferindo a sua livre e expressa concordância com os termos aqui estipulados, incluindo as regras e condições referentes à coleta e armazenamento de dados, ainda que não seja cliente ou tenha outra relação com a Mundo Livre Digital.",
      "paragrafo_termos_4": "Destacamos que o conteúdo do site é destinado para pessoas com capacidade civil plena, caso você não possua essa capacidade plena ou seja menor que 18 anos, é necessário que você esteja representado ou assistido por seus responsáveis legais.",

      /* -------------------MUNDO-ACADEMY ----------------*/
      "botao_prev": "Anterior",
      "botão_next": "Próxima",
      "baixar_pdf": "Download PDF",
      "pagina": "Página",
      "de": "de",


      /* --------------------HUMANOS-SLIDES ----------------------- */

      "tituloHumanosTexto1": 'Podemos mudar o mundo',
      "tituloHumanosTexto2": 'Aprendemos quando cometemos novos erros',
      "tituloHumanosTexto3": 'Autodesenvolvimento é fundamental',
      "tituloHumanosTexto4": 'Estudar é para sempre',

      "conteudoHumanosTexto1": 'Somos joviais, acreditamos que podemos mudar o mundo através da execução de ideias que resolvam um problema de negócio do nosso cliente e que tenham viabilidade técnica e financeira comprovadas. Por isso, todas são bem-vindas e têm a mesma oportunidade de passar pelo processo de validação de ideia!',
      "conteudoHumanosTexto2": 'Para aprender é preciso errar muito, errar rápido e errar barato, diz a teoria. Mas, aqui na Mundo Livre Digital, cometer erros significa também que gostamos de inovar, experimentando e arriscando e quando erramos documentamos o que deu errado e ensinamos uns aos outros, para que este erro não se repita.',
      "conteudoHumanosTexto3": 'Acreditamos que o desenvolvimento pessoal é fundamental. Para isso nos inspiramos nas cinco dimensões do Instituto Inner Development Goals (IDG) que organizam 23 habilidades e qualidades de crescimento e desenvolvimento interior humano.',
      "conteudoHumanosTexto4": 'Somos curiosos…. então gostamos de aprender coisas novas todos os dias!',

      /* --------------------HUMANOS ----------------------- */

      "humanosTitulo": 'Humanos',
      "humanosParagrafo1": 'Todos nós que fazemos parte da Mundo Livre Digital compartilhamos da mesma missão, visão, valores e princípios. Estes fatores definem nossa forma de agir, de pensar e de se comportar. Definem aquilo que somos, nossa cultura e nossa identidade.',
      "humanosParagrafo2": 'Nós acreditamos que:',

      /* -------------------HUMANOS-CARDS ----------------*/
      "humanosTituloCards": 'Conheça o time de seres humanos que lidera nossas equipes:',
      //Rita
      "cargoRita": 'Fundadora e Diretora',
      "bioRita": "Fundadora da Mundo Livre Digital, Rita D'Andrea é graduada em Engenharia Elétrica pela Escola de Engenharia Mauá, com pós-graduação em Finanças pela FGV, Marketing pela ESPM e Direito Digital pela FIA. Iniciou a carreira como analista de suporte, passando por marketing de produto, pré-vendas, vendas, chegando à Diretora de Vendas, e Country Manager, em empresas como NEC, Nortel Networks, HPE, F5 Networks e Genesys. Sempre curiosa e empreendedora, abriu empresas nos segmentos de arte brasileira, consultoria de tecnologia, desenvolvimento de software e alimentação. Depois de 32 anos trabalhando em empresas de alta tecnologia, resolveu criar a sua própria para poder traduzir a tecnologia em benefício efetivo ao negócio, mostrando sua aplicação prática no cotidiano das empresas.",
      //Caique
      "bioCaique": "Caique Alves é um profissional de tecnologia que iniciou sua carreira como estagiário de desenvolvimento em 2019. Em 2021, ele ingressou no Projeto Carambola, onde atuou como desenvolvedor full stack, trabalhando tanto no Front-end quanto no Back-end. Em junho do mesmo ano, Caique foi contratado pela Mundo Livre Digital como Desenvolvedor Pleno. Ele trouxe consigo sua experiência em desenvolvimento full stack, tornando-se uma peça valiosa para a empresa. Caique demonstrou habilidade e comprometimento em seu trabalho e, em 2022, foi promovido a Tech Lead, liderando uma Squad de desenvolvimento. Hoje, Caique é responsável por liderar um time de desenvolvedores, projetando soluções de tecnologia inovadoras e garantindo a qualidade do trabalho de sua equipe.",
      //Guilherme
      "bioGuilherme": "Engenheiro de Computação e competidor internacional de robótica, Guilherme iniciou sua carreira como desenvolvedor Full Stack Junior em 2021, mesmo ano em que passou a integrar o time da Mundo Livre Digital como desenvolvedor júnior. Hoje, ocupa a posição de Tech Lead e lidera uma equipe de desenvolvedores que projetam soluções tecnológicas inovadoras para impulsionar o sucesso da empresa.Antes disso, dedicava seu tempo realizando pentest em aplicações web em busca de Bug Bounty, além de construir e programar robôs com linguagens de baixo nível, o que deu a ele a experiência para lidar com arquiteturas de software complexas.",
      //Thais
      "cargoThais": "Gerente Executiva de Seres Humanos",
      "bioThais": "Estudou Psicologia na Universidade Presbiteriana Mackenzie. Atuou como psicóloga clínica por 2 anos, mudando sua área de atuação em 2011 após realizar o curso Six Sigma. A partir desse momento, o interesse pelos dados passou a ser companheiro do interesse pelas pessoas e suas possibilidades. Atuou por muitos anos, como Analista de Planejamento e, em 2018, fez MBA em Gestão Estratégica de Negócios, buscando unir nos estudos áreas de interesse de sua atuação. Em maio de 2021, começou a trabalhar na Mundo Livre Digital como responsável pela gestão da área de Business Intelligence (BI). Em março de 2023, os propósitos pessoais e da empresa se cruzaram novamente, assumindo então a gestão do SH – Gerente Executiva de Seres Humanos.",
      //Renan
      "cargoRenan": "Supervisor de Suporte",
      "bioRenan": "Renan Medeiros, profissional de engenharia da computação, ingressou na área de tecnologia em 2018, como assistente de HelpDesk e Telecom, com foco no suporte terceirizado a empresas. Com o passar dos anos e cada vez mais entusiasmado com a inovação trazida pela área, em julho de 2021 ingressou na Mundo Livre Digital. Começou como Analista Jr, passou por todos os clientes de suporte, como analista Pleno e Sênior até chegar à Coordenaçao de Suporte onde coordena a equipe responsável pela análise, solução e prevenção de problemas técnicos, além de liderar iniciativas de melhoria contínua para agilizar e aprimorar o suporte oferecido aos clientes.",
      "card_humanos_btn": "Saiba Mais",
    },

  },
  en: {
    translation: {
      home: "HOME",
      about_us: "ABOUT US",
      once_upon_a_time: "ONCE UPON A TIME",
      humans: "HUMANS",
      expertise: "EXPERTISE",
      specialization: "SPECIALIZATION",
      partners: "PARTNERS",
      clients: "CLIENTS",
      mundo_faz: "MUNDOFAZ",
      mundo_agenda: "MUNDOAGENDA",
      mundo_atende: "MUNDOATENDE",
      mundo_dispara: "MUNDODISPARA",
      mundo_academy: "MUNDOACADEMY",
      mundo_catalogue: "MUNDOCATALOGUE",
      contact: "CONTACT",
      work_with_us: "WORK WITH US",
      contact_us: "Contact us",
      privacy_policy: "Privacy policy",
      instagram: "Instagram",
      linkedin: "Linkedin",
      once_upon_a_time_title: "Once upon a time",

      once_upon_a_time_paragraph_1:
        "…Mundo Livre Digital, a company founded in the cloud, which was born from the dream (or delirium ☺) of a woman, who believed it was possible to make the world free and digital, democratizing access to the most advanced information technologies for any size and type of company.",

      once_upon_a_time_paragraph_2:
        "…a company that was a space for its employees to develop their human potential (we all have it and are unique), generating value for the team, for its Clients and Partners.",

      once_upon_a_time_paragraph_3:
        "Mundo Livre Digital was created with the aim of helping Humans to get rid of mundane and repetitive activities, to have time to think, create and incorporate innovations into their businesses.",

      once_upon_a_time_paragraph_4:
        "We share the goals of the Humane Tech movement, whose mission is to “shift technology towards a more humane future that supports our well-being, democratic functioning and information-sharing environment.",

      woman_alt_text: "Woman holding a cellphone",
      group_people_alt_text: "Banner group of people",
      wilson_quote:
        "“The real problem of humanity is this: We have paleolithic emotions, medieval institutions and divine technology.”",

      especializacao_title: "Specialization",

      especializacao_paragrafo_1:
        "We specialize in providing customer service solutions that address problems by industry vertical.",

      especializacao_paragrafo_2:
        "We use Customer Service Platforms and Artificial Intelligence offered “as-a-service” to:",

      especializacao_lista_item_1:
        "Scale personalization and make your customers feel special;",

      especializacao_lista_item_2:
        "Be empathetic, creating context-driven interactions where the customer feels understood and supported;",

      especializacao_lista_item_3:
        "Provide objective service without wasting time, getting straight to the point without repeating information.",

      parceiros_title: "Partners",

      parceiros_paragrafo:
        "We collaborate with renowned companies known for valuing innovation and believing that diversity is a key factor in the evolution of humanity.",

      mundo_atende_title: "MundoAtende",
      mundo_atende_subtitle:
        "Elevate your company's service standards by optimizing your employees' time and providing a differentiated experience for your customers.",
      mundo_atende_description1:
        "MundoAtende is a customer service tool via WhatsApp, providing your clients with personalized, fast, and efficient service. In addition, it offers your attendants an optimized and intuitive work environment.",
      mundo_atende_how_it_works: "How it works",
      mundo_atende_description2:
        "With the MundoAtende customer service solution, you offer the public the highest level of security and convenience!",
      mundo_atende_description3:
        "Create personalized menus so that customers are directed by reason of contact to the employee best qualified to assist them.",
      mundo_atende_description4:
        "If necessary, transfer the customer's interaction to other areas of the company quickly and efficiently, ensuring prompt service.",
      mundo_atende_description5:
        "Facilitate the exchange of information by allowing attachments to be sent between the company and customers, and make communication more relaxed by using emojis.",
      mundo_atende_image_alt: "Banner mundo atende",
      clientes_title: "Customers",
      clientes_paragrafo: "Meet some of our clients:",

      "mundo_agenda_title": "MundoAgenda",
      "mundo_agenda_subtitle": "Scheduling and confirming via WhatsApp has never been so easy and efficient!",
      "mundo_agenda_paragrafo_1": "MundoAgenda is an artificial intelligence tool connected to the MundoAtende platform via WhatsApp. It allows you to schedule, reschedule, and cancel appointments automatically in your scheduling system, keeping it always up-to-date.",
      "mundo_agenda_paragrafo_2": "The main advantage for your company is the reduction in the number of attendants and infrastructure costs, as scheduling, confirmation, cancellation, and rescheduling are handled by artificial intelligence.",
      "mundo_agenda_link": "Learn more.",

      //MUNDO DISPARA ------------------------

      "mundo_dispara_title": "MundoDispara",
      "mundo_dispara_paragrafo_1": "MundoDispara is an omnichannel tool for interactive text or voice communications, enabling customers to interact with your company quickly and directly.",

      "MD_telemedicina_title": "Telemedicine",
      "MD_telemedicina_lista_1": "When the patient enters the video conference room and notices that the doctor is late, they move away from their phone or computer.",
      "MD_telemedicina_lista_2": "When the doctor enters the room, the patient is connected but not present.",
      "MD_telemedicina_lista_3": "MundoDispara makes a voice call to the patient.",
      "MD_telemedicina_lista_4": "When the patient answers the call, they hear a message asking them to return to the consultation room and have the option to speak with a human.",

      "MD_operadoraSaude_title": "Health Operator",
      "MD_operadoraSaude_lista_1": "Every Monday, HealthCloud generates a list of patients who visited the Emergency Room over the weekend and sends the data to MundoDispara;",
      "MD_operadoraSaude_lista_2": "When answering the call, the contact details are verified;",
      "MD_operadoraSaude_lista_3": "Next, it asks if the patient is still experiencing the symptoms that led to their visit to the Emergency Room and if they wish to be seen by a nurse;",
      "MD_operadoraSaude_lista_4": "If yes, the call is transferred to nurses.",

      "MD_hospital_title": "Hospital",
      "MD_hospital_lista_1": "Every day, a list of potential patients who will be discharged the following day is generated;",
      "MD_hospital_lista_2": "This list is uploaded to MundoDispara by the nursing staff themselves to send a message to the patient's companion via WhatsApp, informing them about the hospital discharge procedures;",
      "MD_hospital_lista_3": "If there are any questions, the companion can clarify them by chatting with a human via WhatsApp.",

      "MD_marketplace_title": "Restaurant Marketplace",
      "MD_marketplace_lista_1": "The Marketplace wants to notify restaurant owners in a new area that they can now register on the platform;",
      "MD_marketplace_lista_2": "MundoDispara, using a database, sends the invitation message;",
      "MD_marketplace_lista_3": "Upon response, the restaurant information is validated;",
      "MD_marketplace_lista_4": "Next, a message is sent announcing that registration for the Marketplace in this region is now available;",
      "MD_marketplace_lista_5": "If the owner is interested in registering, a link to the registration form on the Marketplace is sent.",
      "contatoTitulo": "Contact us",
      "contatoTelefone": "Telephone",

      "mapaMLD": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14627.481807588374!2d-46.6566002!3d-23.5730958!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce574dcdd00001%3A0xbf3aa754aebdd392!2sMundo%20Livre%20Digital!5e0!3m2!1sen-US!2sbr!4v1724271384946!5m2!1sen-US!2sbr",

      "titulo_form": "Contact form",
      "botao_form": "Leave your contact",
      "nome_form": "Name",
      "telefone_form": "Telephone",
      "enviar_form": "Send",
      "email_form": "Email",
      "termo_servico_1": "By clicking submit, you agree to our",
      "termo_servico_2": "Terms of Service",
      "termo_servico_3": "and that you read our",
      "termo_servico_4": "Privacy Policy",
      "autorizacao_envio_messagem": "I authorize the sending of notifications by SMS/Promotional Message/Informational Message",

      /* ------------------- PÁGINA POLITICAS PRIVACIDADE ----------------*/
      "titulo_principal_politicas": "Policies and Terms",
      "titulo_dropdown_politicas": "Privacy Policy",
      "titulo_principal_politicas2": "Privacy Policy",
      "politicas_titulo_1": "1. Data that may be collected due to the use of the website",
      "paragrafo_politicas_1": "Due to the use of one of the means indicated for contact indicated on the Website, registration data may be collected, such as name, email, telephone, among other information necessary for the continuity of the service of your demand.",
      "politicas_titulo_2": "2. Link to third party websites",
      "paragrafo_politicas_2": "The website contains links to third-party websites for the purpose of applying for a job opportunity at Mundo Livre Digital. This service is provided by the company REACHR and, if you access the page or apply for an opportunity through this link, you agree to the terms of use and privacy policy of the partner company available at the links https://www.reachr.com.br/termos-de-uso/ and https://www.reachr.com.br/politica-de-privacidade/, which we recommend that you read carefully so that you can find out how your personal data is processed. Mundo Livre Digital is not responsible for the partner website and its contents.",
      "politicas_titulo_3": "3. Cookies Used on the Site",
      "paragrafo_politicas_3_1": "MUNDO LIVRE DIGITAL uses cookies to improve the user experience on the website. Cookies can store information about the user who accesses the website, such as: location, accesses, origin of access, statistics, address; customers, countries, IP address, device used to access. Cookies, which are small text files stored on your hard drive and assigned to the browser used that send information to the person who created them, used on the MUNDO LIVRE DIGITAL website perform functions: (a) necessary to allow navigation on the website (functional cookies); (b) improve the user experience (tracking cookies); and (b) collect statistical data (analytical cookies). The cookies used on the website are temporary and persistent. The former are automatically deleted after closing the browser and may store a session ID to facilitate your recognition on a future visit. Persistent cookies remain on your device for a considerable time or until you delete them, and you can delete cookies in your browser's security settings at any time. Failure to accept third-party cookies or all cookies may prevent or impair navigation and use of the website.",
      "paragrafo_politicas_3_2": "The cookies used on the website are temporary and persistent. The former are automatically deleted after closing the browser and may store a session ID to facilitate your recognition on a future visit. Persistent cookies remain on your device for a considerable time or until you delete them. You can delete cookies in your browser's security settings at any time.",
      "paragrafo_politicas_3_3": "Failure to accept third-party cookies or all cookies may prevent or impair navigation and use of the website.",
      "paragrafo_politicas_3_4": "The third-party cookies used on the website are:",
      "lista_politicas_3_1": "Google Analytics: (Tracker cookies) collects statistical data in order to measure and monitor the performance of the website, allowing the monitoring of the website user profile, most visited pages, cities, countries, IP and time of access to the website;",
      "lista_politicas_3_2": "Google Tag Manager, designed to manage website tags through a user interface;",
      "lista_politicas_3_3": "Google Maps: Informs the employee of the location of the company's headquarters.",
      "paragrafo_politicas_3_5_1": "Note: The veracity and accuracy of the data entered in the fields on the website are the sole responsibility of the user, and MUNDO LIVRE DIGITAL is not responsible for the veracity or accuracy of the data or information provided by users. The user may request the correction of their data on the website by sending an e-mail to the address",
      "paragrafo_politicas_3_5_2": "indicating the topic Privacy in the subject field.",
      "paragrafo_politicas_3_6": "We emphasize that MUNDO LIVRE DIGITAL does not sell or commercialize collected personal data, and the collected personal data is used solely for the purposes for which it was collected, which, as mentioned above, does not prevent the sharing of data with third parties to fulfill the purposes of MUNDO LIVRE DIGITAL.",
      "politicas_titulo_4": "4. Intellectual Property",
      "paragrafo_politicas_4": "All content available on the website, information, news, images, brands, regardless of the form of presentation, is the exclusive property or license of MUNDO LIVRE DIGITAL. Therefore, the website user is prohibited from:",
      "lista_politicas_4_1": "Reproduce, republish, duplicate or copy any content available on the website, especially brands and products owned by Mundo Livre Digital;",
      "lista_politicas_4_2": "Sell, rent, sublicense and/or otherwise commercialize any content on the Mundo Livre Digital website, without prior contracting or authorization;",
      "lista_politicas_4_3": "Publicly perform and/or display any Mundo Livre Digital content without prior authorization from Mundo Livre Digital;",
      "lista_politicas_4_4": "Use the website in a way that could damage and/or affect access to the website or our brand;",
      "lista_politicas_4_5": "Using this website contrary to the relevant rules, laws and regulations of Brazil and/or your country of residence;",
      "lista_politicas_4_6": "Conduct data mining or any other similar activity in relation to this Website, or while using this Website; and",
      "lista_politicas_4_7": "Use this website to develop any form of advertising or business marketing, without prior authorization from Mundo Livre Digital.",
      "politicas_titulo_5": "5. Contact Form",
      "paragrafo_politicas_5_1": "To eliminate any doubts about these Terms of Use, you can send an email to:",
      "paragrafo_politicas_5_2": "indicating the topic “Privacy” in the subject field.",
      "politicas_titulo_6": "6. General Provisions",
      "paragrafo_politicas_6_1": "Additional information can be found in our privacy policy.",
      "paragrafo_politicas_6_2": "The website may go through periods of unavailability for various reasons, such as updates, changes, maintenance, etc., and may be taken offline at the sole discretion and at any time by MUNDO LIVRE DIGITAL. Failure to comply with any clause of these Terms of Use may result in liability in the civil and criminal spheres, when applicable and taking into account the nature of the non-compliance. These Terms of Use were approved on October 23, 2024 and will remain in effect until further revocation or modification by MUNDO LIVRE DIGITAL, which reserves the right to make, at any time, changes to its privacy policy, and the updated version will be made available on the website mundolivre.digital. These Terms of Use will be governed, interpreted and executed in accordance with the laws of Brazil, with the Court of the District of São Paulo - SP being elected to resolve any doubts arising from this instrument.",
      "link_pagina_contato_politicas": "Contact page",

      "titulo_dropdown_termos": "Terms of Service",
      "paragrafo_termos_1": "Reaffirming its commitment to the integrity and protection of the privacy of the website user and its services, MUNDO LIVRE DIGITAL has drawn up these Website Terms of Use, in which it undertakes to respect Law No. 13,709/2018, the General Data Protection Law.",
      "paragrafo_termos_2": "This Term of Use establishes the conditions for using the Website and accessing its features, which include access to information about the products offered by us, information about our team, access to Mundo Livre Digital service channels and registration for job opportunities if you are interested in joining the Mundo Livre Digital team.",
      "paragrafo_termos_3": "As a visitor to the website, you acknowledge and declare that you have read and accepted the rules described in this document, giving your free and express agreement to the terms stipulated herein, including the rules and conditions regarding the collection and storage of data, even if you are not a customer or have any other relationship with Mundo Livre Digital.",
      "paragrafo_termos_4": "We emphasize that the content of the website is intended for people with full civil capacity. If you do not have full capacity or are under 18 years of age, you must be represented or assisted by your legal guardians.",


      /* -------------------MUNDO-ACADEMY ----------------*/
      "botao_prev": "Previous",
      "botão_next": "Next",
      "baixar_pdf": "Download PDF",
      "pagina": "Page",
      "de": "of",


      /* --------------------HUMANS-SLIDES ----------------------- */

      "tituloHumanosTexto1": 'We can change the world',
      "tituloHumanosTexto2": 'We learn when we make new mistakes',
      "tituloHumanosTexto3": 'Self-development is essential',
      "tituloHumanosTexto4": 'Studying is forever',

      "conteudoHumanosTexto1": 'We are youthful, and we believe that we can change the world by executing ideas that solve a business problem for our clients and have proven technical and financial viability. Therefore, all ideas are welcome and have the same opportunity to go through the validation process!',
      "conteudoHumanosTexto2": 'To learn, you must make many mistakes, make them quickly and cheaply, says the theory. But here at Mundo Livre Digital, making mistakes also means we like to innovate, experimenting and taking risks. When we make mistakes, we document what went wrong and teach each other so that this mistake does not happen again.',
      "conteudoHumanosTexto3": 'We believe that personal development is fundamental. For this, we are inspired by the five dimensions of the Inner Development Goals Institute (IDG) that organize 23 skills and qualities of human inner growth and development.',
      "conteudoHumanosTexto4": 'We are curious... so we like to learn new things every day!',

      /* --------------------HUMANS ----------------------- */

      "humanosTitulo": 'Humans',
      "humanosParagrafo1": 'All of us who are part of Mundo Livre Digital share the same mission, vision, values, and principles. These factors define our way of acting, thinking, and behaving. They define who we are, our culture, and our identity.',
      "humanosParagrafo2": 'We believe that:',

      /* -------------------HUMANS-CARDS ----------------*/
      "humanosTituloCards": 'Meet the team of humans leading our teams:',
      "cargoRita": 'Founder and Director',
      "bioRita": "Founder of Mundo Livre Digital, Rita D'Andrea graduated in Electrical Engineering from Mauá Engineering School, with postgraduate degrees in Finance from FGV, Marketing from ESPM, and Digital Law from FIA. She started her career as a support analyst, moving through product marketing, pre-sales, sales, reaching Sales Director and Country Manager positions in companies like NEC, Nortel Networks, HPE, F5 Networks, and Genesys. Always curious and entrepreneurial, she opened companies in Brazilian art, technology consulting, software development, and food sectors. After 32 years working in high-tech companies, she decided to create her own to translate technology into effective benefits for business, showing its practical application in the daily operations of companies.",
      "bioCaique": "Caique Alves is a technology professional who started his career as a development intern in 2019. In 2021, he joined the Carambola Project, where he worked as a full-stack developer, handling both Front-end and Back-end tasks. In June of the same year, Caique was hired by Mundo Livre Digital as a Mid-level Developer. He brought with him his experience in full-stack development, becoming a valuable asset to the company. Caique demonstrated skill and commitment in his work and, in 2022, was promoted to Tech Lead, leading a development Squad. Today, Caique is responsible for leading a team of developers, designing innovative technology solutions, and ensuring the quality of his team's work.",
      "bioGuilherme": "A Computer Engineering graduate and international robotics competitor, Guilherme started his career as a Junior Full Stack Developer in 2021, the same year he joined Mundo Livre Digital as a junior developer. He currently holds the position of Tech Lead, leading a team of developers who design innovative technological solutions to drive the company's success. Prior to this, he spent his time conducting pentests on web applications in search of Bug Bounty, as well as building and programming robots with low-level languages, which gave him the experience to deal with complex software architectures.",
      "cargoThais": "Executive Manager of Humans",
      "bioThais": "Studied Psychology at Mackenzie Presbyterian University. Worked as a clinical psychologist for 2 years, changing her area of focus in 2011 after completing the Six Sigma course. From that moment on, her interest in data became intertwined with her interest in people and their possibilities. She worked for many years as a Planning Analyst, and in 2018, she completed an MBA in Strategic Business Management, seeking to unite her areas of interest in her studies. In May 2021, she began working at Mundo Livre Digital, managing the Business Intelligence (BI) area. In March 2023, her personal goals and those of the company intersected again, leading her to take over the management of SH – Executive Manager of Humans.",
      "cargoRenan": "Support Supervisor",
      "bioRenan": "Renan Medeiros, a computer engineering professional, entered the technology field in 2018 as a HelpDesk and Telecom assistant, focusing on outsourced support for companies. Over the years, increasingly excited about the innovation brought by the field, he joined Mundo Livre Digital in July 2021. He started as a Junior Analyst, went through all support clients as a Mid and Senior Analyst, until he reached the Support Coordination position where he leads the team responsible for analyzing, solving, and preventing technical issues, in addition to leading continuous improvement initiatives to streamline and enhance the support provided to clients.",
      "card_humanos_btn": "Learn More",



    },
  },
  es: {
    translation: {
      home: "INICIO",
      about_us: "SOBRE NOSOTROS",
      once_upon_a_time: "ÉRASE UNA VEZ",
      humans: "HUMANOS",
      expertise: "PERICIA",
      specialization: "ESPECIALIZACIÓN",
      partners: "SOCIOS",
      clients: "CLIENTES",
      mundo_faz: "MUNDOFAZ",
      mundo_agenda: "MUNDOAGENDA",
      mundo_atende: "MUNDOATIENDE",
      mundo_dispara: "MUNDOENVÍA",
      mundo_academy: "MUNDOACADEMY",
      mundo_catalogue: "MUNDOCATÁLOGO",
      contact: "CONTACTO",
      work_with_us: "TRABAJA CON NOSOTROS",
      contact_us: "¡Habla con nosotros!",
      privacy_policy: "Política de privacidad",
      instagram: "Instagram",
      linkedin: "Linkedin",
      once_upon_a_time_title: "Érase una vez",
      once_upon_a_time_paragraph_1:
        "...Mundo Libre Digital, una empresa fundada en la nube, que nació del sueño (o delirio ☺) de una mujer que creía que era posible hacer que el mundo fuera libre y digital, democratizando el acceso a las más avanzadas tecnologías de la información para empresas de todos los tamaños y tipos.",

      once_upon_a_time_paragraph_2:
        "una empresa que era un espacio donde sus colaboradores podían desarrollar su potencial humano (todos somos únicos), generando valor para el equipo, para sus clientes y socios.",

      once_upon_a_time_paragraph_3:
        "Mundo Livre Digital nació con la misión de liberar a las personas de tareas rutinarias y repetitivas, dándoles tiempo para pensar, innovar y hacer crecer sus negocios.",

      once_upon_a_time_paragraph_4:
        "Compartimos los objetivos del movimiento. Humane Tech, cuya misión es transformar la tecnología hacia un futuro más humano que promueva nuestro bienestar, el funcionamiento democrático y un entorno de información compartida.”",
      woman_alt_text: "Mujer sosteniendo un celular",
      group_people_alt_text: "Banner grupo de personas",
      wilson_quote:
        "“El verdadero problema de la humanidad es este: tenemos emociones paleolíticas, instituciones medievales y tecnología divina.”",

      especializacao_title: "Especialización",

      especializacao_paragrafo_1:
        "Nos especializamos en brindar un servicio al cliente que resuelve problemas por industria vertical.",

      especializacao_paragrafo_2:
        "Utilizamos plataformas de inteligencia artificial y como servicio para:",

      especializacao_lista_item_1:
        "Personalización masiva y haz que tus clientes se sientan especiales;",

      especializacao_lista_item_2:
        "Ser empático, creando servicios con contexto, donde el cliente se sienta comprendido y acogido;",

      especializacao_lista_item_3:
        "Ofrecer una asistencia objetiva, sin perder tiempo, yendo directo al grano, sin repetir información.",

      parceiros_title: "Socios",

      parceiros_paragrafo:
        "Contamos con la colaboración de empresas reconocidas, conocidas por valorar la innovación y creer que la diversidad es un factor importante para la evolución del ser humano.",

      mundo_atende_title: "MundoAtende",
      mundo_atende_subtitle:
        "Eleva el estándar de atención de tu empresa, optimizando el tiempo de tus colaboradores y ofreciendo un servicio diferenciado a tus clientes.",
      mundo_atende_description1:
        "MundoAtende es una herramienta de atención a través de WhatsApp, que proporciona a  tus clientes un servicio personalizado, ágil y eficiente. Además, ofrece a tus atendientes un entorno de trabajo optimizado e intuitivo.",
      mundo_atende_how_it_works: "¿Cómo funciona?",
      mundo_atende_description2:
        "¡Con la solución de atención MundoAtende, ofreces al público un servicio con el más alto nivel de seguridad y practicidad!",
      mundo_atende_description3:
        "Crea menús personalizados para que los clientes sean dirigidos, según el motivo de contacto, al empleado más calificado para atenderlos. ",
      mundo_atende_description4:
        "Si es necesario, transfiere la interacción del cliente a otras áreas de la empresa de manera rápida y eficiente, garantizando agilidad en el servicio.",
      mundo_atende_description5:
        "Facilita el intercambio de información, permitiendo el envío de archivos entre la empresa y los clientes, y haz la comunicación más relajada utilizando emojis.",
      mundo_atende_image_alt: "Banner mundo atende",
      clientes_title: "Clientes",
      clientes_paragrafo: "Conozca a algunos de nuestros clientes:",

      "mundo_agenda_title": "MundoAgenda",
      "mundo_agenda_subtitle": "¡Agendar y confirmar por WhatsApp nunca ha sido tan fácil y eficiente!",
      "mundo_agenda_paragrafo_1": "MundoAgenda es una herramienta de inteligencia artificial conectada a la herramienta MundoAtende, a través de WhatsApp. Con ella es posible agendar, reprogramar y cancelar compromisos automáticamente en el su sistema de agendamiento, manteniéndolo siempre actualizado.",
      "mundo_agenda_paragrafo_2": "La principal ventaja para su empresa es reducir la cantidad de atendientes y los gastos con infraestructura, ya que la programación, la confirmación, la cancelación y la reprogramación se realizan mediante inteligencia artificial.",
      "mundo_agenda_link": "Saber más.",

      //MUNDO DISPARA ------------------------

      "mundo_dispara_title": "MundoDispara",
      "mundo_dispara_paragrafo_1": "O MundoDispara es una herramienta omnicanal de contactos interactivos por texto o voz, que permite que los clientes se comuniquen con tu empresa de manera ágil y directa.",

      "MD_telemedicina_title": "Telemedicina",
      "MD_telemedicina_lista_1": "El paciente, al ingresar a la sala de videoconferencia, nota que el médico está retrasado y se aleja del celular o del computador;",
      "MD_telemedicina_lista_2": "Cuando el médico entra en la sala, el paciente está conectado, pero no está presente;",
      "MD_telemedicina_lista_3": "MundoDispara realiza una llamada de voz al paciente;",
      "MD_telemedicina_lista_4": "Al contestar la llamada, el paciente escucha un mensaje solicitando que regrese a la sala de consulta y tiene la opción de hablar con un humano.",

      "MD_operadoraSaude_title": "Operadora de Salud",
      "MD_operadoraSaude_lista_1": "Todos los lunes, HealthCloud genera una lista de pacientes que pasaron por la Sala de Urgencias durante el fin de semana y envía la base de datos a MundoDispara;",
      "MD_operadoraSaude_lista_2": "Al atender la llamada, se validan los datos de contacto;",
      "MD_operadoraSaude_lista_3": "Luego, se pregunta si el paciente aún presenta los síntomas por los que visitó la Sala de Urgencias y si desea ser atendido por el personal de Enfermería;",
      "MD_operadoraSaude_lista_4": "En caso afirmativo, la llamada es transferida a los enfermeros.",

      "MD_hospital_title": "Hospital",
      "MD_hospital_lista_1": "Todos los días se genera una lista de posibles pacientes que serán dados de alta al día siguiente;",
      "MD_hospital_lista_2": "Esta base de datos es cargada en MundoDispara por el personal de enfermería para enviar un mensaje al acompañante del paciente a través de WhatsApp, informando sobre los procedimientos de alta hospitalaria;",
      "MD_hospital_lista_3": "Si el acompañante tiene alguna duda, puede resolverla chateando con un humano vía WhatsApp.",

      "MD_marketplace_title": "Marketplace de Restaurantes",
      "MD_marketplace_lista_1": "El Marketplace quiere informar a los dueños de restaurantes en una nueva localidad que ya es posible registrarse en la plataforma;",
      "MD_marketplace_lista_2": "MundoDispara,  envía el mensaje de invitación a partir de una base de datos;",
      "MD_marketplace_lista_3": "Al responder, se validan las informaciones del restaurante;",
      "MD_marketplace_lista_4": "A continuación, se envía un mensaje anunciando que el registro en el Marketplace para esta región ya está disponible;",
      "MD_marketplace_lista_5": "Si el propietario está interesado en registrarse, se envía un enlace de acceso al formulario en el Marketplace.",
      "contatoTitulo": "Entre en contacto",
      "contatoTelefone": "Teléfono",

      "mapaMLD": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14627.481807588374!2d-46.6566002!3d-23.5730958!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce574dcdd00001%3A0xbf3aa754aebdd392!2sMundo%20Livre%20Digital!5e0!3m2!1ses-ES!2sbr!4v1724271384946!5m2!1ses-ES!2sbr",

      "titulo_form": "Formulario de contacto",
      "botao_form": "Deja tu contacto",
      "nome_form": "Nombre",
      "telefone_form": "Teléfono",
      "enviar_form": "Enviar",
      "email_form": "Correo electrónico",
      "termo_servico_1": "Al hacer clic en enviar, aceptas nuestro",
      "termo_servico_2": "Condiciones de servicio",
      "termo_servico_3": "Y que lees nuestra",
      "termo_servico_4": "política de privacidad",
      "autorizacao_envio_messagem": "Autorizo ​el envío de notificaciones por SMS/Mensaje Promocional/Mensaje Informativo",


      /* ------------------- PÁGINA POLITICAS PRIVACIDADE ----------------*/

      "titulo_principal_politicas": "Políticas y términos",
      "titulo_dropdown_politicas": "Política de privacidad",
      "titulo_principal_politicas2": "Política de privacidad",
      "politicas_titulo_1": "1. Datos que pueden ser recogidos debido al uso del sitio web",
      "paragrafo_politicas_1": "Debido al uso de alguno de los medios indicados para el contacto en el sitio web, se podrán recopilar datos de registro, como nombre, correo electrónico, teléfono, entre otra información necesaria para dar seguimiento a su solicitud.",
      "politicas_titulo_2": "2. Enlace a sitios web de terceros",
      "paragrafo_politicas_2": "El sitio web contiene enlaces a sitios web de terceros para solicitar una oportunidad de trabajo en Mundo Livre Digital. Este servicio es proporcionado por la empresa REACHR y, al acceder a la página o solicitar una oportunidad a través de este enlace, usted acepta los términos de uso y la política de privacidad de la empresa asociada disponibles en los enlaces https://www.reachr.com.br/termos-de-uso/ y https://www.reachr.com.br/politica-de-privacidade/. Le recomendamos leerlos atentamente para conocer cómo se procesan sus datos personales. Mundo Livre Digital no es responsable del sitio web del socio ni de su contenido.",
      "politicas_titulo_3": "3. Cookies utilizadas en el sitio web",
      "paragrafo_politicas_3_1": "MUNDO LIVRE DIGITAL utiliza cookies para mejorar la experiencia del usuario en el sitio web. Las cookies pueden almacenar información del usuario que accede al sitio web, como ubicación, origen del acceso, estadísticas, dirección IP, dispositivo utilizado, entre otros. Las cookies, que son pequeños archivos de texto almacenados en su disco duro y asignados al navegador utilizado, envían información al creador. Las cookies utilizadas en el sitio web de MUNDO LIVRE DIGITAL cumplen funciones: (a) necesarias para permitir la navegación en el sitio web (cookies funcionales); (b) mejorar la experiencia del usuario (cookies de seguimiento); y (c) recopilar datos estadísticos (cookies analíticas). Las cookies utilizadas en el sitio web pueden ser temporales o persistentes. Las primeras se eliminan automáticamente al cerrar el navegador, mientras que las persistentes permanecen en su dispositivo durante un período prolongado o hasta que las elimine. Puede eliminar las cookies en la configuración de seguridad de su navegador en cualquier momento. No aceptar cookies de terceros o todas las cookies puede dificultar la navegación y el uso del sitio web.",
      "paragrafo_politicas_3_2": "Las cookies utilizadas en el sitio web pueden ser temporales o persistentes. Las temporales se eliminan automáticamente al cerrar el navegador y pueden almacenar ID de sesión para facilitar el reconocimiento en una visita futura. Las cookies persistentes permanecen en su dispositivo durante un período prolongado o hasta que las elimine. Puede eliminar las cookies en la configuración de seguridad de su navegador en cualquier momento.",
      "paragrafo_politicas_3_3": "No aceptar cookies de terceros o todas las cookies puede dificultar la navegación y el uso del sitio web.",
      "paragrafo_politicas_3_4": "Las cookies de terceros utilizadas en el sitio web son:",
      "lista_politicas_3_1": "",
      "lista_politicas_3_2": "Google Tag Manager: utilizado para la administración de etiquetas de sitios a través de una interfaz de usuario.",
      "lista_politicas_3_3": "Google Maps: informa al usuario sobre la ubicación de la sede de la empresa.",
      "paragrafo_politicas_3_5_1": "Nota: La veracidad y precisión de los datos ingresados en los campos del sitio son responsabilidad exclusiva del usuario. MUNDO LIVRE DIGITAL no se hace responsable de la veracidad o precisión de los datos o información proporcionada por los usuarios. El usuario podrá solicitar la corrección de sus datos en el sitio enviando un correo electrónico a",
      "paragrafo_politicas_3_5_2": "indicando en el asunto el tema Privacidad.",
      "paragrafo_politicas_3_6": "Reiteramos que MUNDO LIVRE DIGITAL no vende ni comercializa datos personales recopilados. Los datos personales recopilados se utilizan exclusivamente para los fines para los que fueron recopilados. Como se mencionó anteriormente, esto no impide el intercambio de datos con terceros para cumplir con los objetivos de MUNDO LIVRE DIGITAL.",
      "politicas_titulo_4": "4. Propiedad Intelectual",
      "paragrafo_politicas_4": "Todo el contenido disponible en el sitio, como información, noticias, imágenes y marcas, independientemente de su forma de presentación, es propiedad exclusiva o está licenciado a MUNDO LIVRE DIGITAL. Por lo tanto, está prohibido que el usuario del sitio:",
      "lista_politicas_4_1": "Reproducir, republicar, duplicar o copiar cualquier contenido disponible en el sitio, especialmente las marcas y productos de MUNDO LIVRE DIGITAL.",
      "lista_politicas_4_2": "Vender, alquilar, sublicenciar y/o comercializar cualquier contenido del sitio de MUNDO LIVRE DIGITAL sin previa autorización.",
      "lista_politicas_4_3": "Ejecutar y/o mostrar públicamente cualquier contenido de MUNDO LIVRE DIGITAL sin previa autorización.",
      "lista_politicas_4_4": "Usar el sitio de una manera que pueda dañar y/o afectar el acceso al sitio o a nuestra marca.",
      "lista_politicas_4_5": "Usar este sitio en contra de las leyes y regulaciones relevantes de Brasil y/o de su país de residencia.",
      "lista_politicas_4_6": "Realizar minería de datos o cualquier actividad similar relacionada con este sitio.",
      "lista_politicas_4_7": "Usar este sitio para desarrollar cualquier forma de publicidad o marketing sin previa autorización de MUNDO LIVRE DIGITAL.",
      "politicas_titulo_5": "5. Forma de Contacto",
      "paragrafo_politicas_5_1": "Para aclarar cualquier duda sobre estos Términos de Uso, puede enviar un correo electrónico a:",
      "paragrafo_politicas_5_2": "indicando en el asunto el tema Privacidad.",
      "politicas_titulo_6": "6. Disposiciones Generales",
      "paragrafo_politicas_6_1": "Puede encontrar información adicional en nuestra política de privacidad.",
      "paragrafo_politicas_6_2": "El sitio puede estar temporalmente fuera de servicio por diversas razones, como actualizaciones, cambios, mantenimiento, etc. También puede ser retirado a discreción exclusiva de MUNDO LIVRE DIGITAL. El incumplimiento de cualquier cláusula de estos Términos de Uso puede resultar en responsabilidades civiles y penales según corresponda. Estos Términos de Uso fueron aprobados el 23/10/2024 y estarán vigentes hasta su posterior revocación o modificación por parte de MUNDO LIVRE DIGITAL, que se reserva el derecho de realizar cambios en su política de privacidad en cualquier momento. La versión actualizada estará disponible en el sitio mundolivre.digital. Estos Términos de Uso se regirán, interpretarán y ejecutarán de acuerdo con las leyes de Brasil, eligiendo el foro de la Comarca de São Paulo-SP para resolver cualquier disputa.",
      "link_pagina_contato_politicas": "Página de contacto",
      "titulo_dropdown_termos": "Términos de Servicio",
      "paragrafo_termos_1": "Reafirmando su compromiso con la integridad y protección de la privacidad del usuario del sitio y sus servicios, MUNDO LIVRE DIGITAL ha elaborado estos Términos de Uso del Sitio, comprometiéndose a respetar la Ley nº. 13.709/2018, Ley General de Protección de Datos.",
      "paragrafo_termos_2": "Estos Términos de Uso establecen las condiciones para utilizar el sitio y acceder a sus funcionalidades, incluyendo el acceso a información sobre los productos ofrecidos por nosotros, información sobre nuestro equipo, acceso a los canales de atención de MUNDO LIVRE DIGITAL y registro para oportunidades laborales en caso de interés.",
      "paragrafo_termos_3": "Como visitante del sitio, usted reconoce y declara haber leído y aceptado las reglas descritas en este documento, dando su libre y expresa conformidad con los términos aquí estipulados, incluyendo las reglas y condiciones relativas a la recopilación y almacenamiento de datos, incluso si no es cliente ni tiene otra relación con MUNDO LIVRE DIGITAL.",
      "paragrafo_termos_4": "Destacamos que el contenido del sitio está destinado a personas con plena capacidad civil. Si no tiene esta capacidad plena o es menor de 18 años, deberá estar representado o asistido por sus responsables legales.",




      /* -------------------MUNDO-ACADEMY ----------------*/
      "botao_prev": "Anterior",
      "botão_next": "Siguiente",
      "baixar_pdf": "Descargar PDF",
      "pagina": "Página",
      "de": "de",

      /* --------------------HUMANOS-SLIDES ----------------------- */

      "tituloHumanosTexto1": 'Podemos cambiar el mundo',
      "tituloHumanosTexto2": 'Aprendemos cuando cometemos nuevos errores',
      "tituloHumanosTexto3": 'El autodesarrollo es esencial',
      "tituloHumanosTexto4": 'Estudiar es para siempre',

      "conteudoHumanosTexto1": 'Somos jóvenes y creemos que podemos cambiar el mundo a través de la ejecución de ideas que resuelvan un problema de negocio para nuestros clientes y que tengan viabilidad técnica y financiera comprobadas. Por eso, ¡todas son bienvenidas y tienen la misma oportunidad de pasar por el proceso de validación de ideas!',
      "conteudoHumanosTexto2": 'Para aprender, hay que cometer muchos errores, cometerlos rápido y barato, dice la teoría. Pero aquí en Mundo Livre Digital, cometer errores también significa que nos gusta innovar, experimentando y arriesgando, y cuando cometemos errores, documentamos lo que salió mal y enseñamos a los demás, para que este error no se repita.',
      "conteudoHumanosTexto3": 'Creemos que el desarrollo personal es fundamental. Para ello, nos inspiramos en las cinco dimensiones del Instituto Inner Development Goals (IDG) que organizan 23 habilidades y cualidades de crecimiento y desarrollo interior humano.',
      "conteudoHumanosTexto4": 'Somos curiosos... ¡así que nos gusta aprender cosas nuevas todos los días!',

      /* --------------------HUMANOS ----------------------- */

      "humanosTitulo": 'Humanos',
      "humanosParagrafo1": 'Todos nosotros que formamos parte de Mundo Livre Digital compartimos la misma misión, visión, valores y principios. Estos factores definen nuestra forma de actuar, pensar y comportarnos. Definen quiénes somos, nuestra cultura y nuestra identidad.',
      "humanosParagrafo2": 'Creemos que:',

      /* -------------------HUMANOS-CARDS ----------------*/
      "humanosTituloCards": 'Conoce al equipo de humanos que lidera nuestras equipos:',
      "cargoRita": 'Fundadora y Directora',
      "bioRita": "Fundadora de Mundo Livre Digital, Rita D'Andrea se graduó en Ingeniería Eléctrica en la Escuela de Ingeniería Mauá, con posgrado en Finanzas por la FGV, Marketing por la ESPM y Derecho Digital por la FIA. Comenzó su carrera como analista de soporte, pasando por marketing de producto, preventas, ventas, alcanzando los cargos de Directora de Ventas y Country Manager en empresas como NEC, Nortel Networks, HPE, F5 Networks y Genesys. Siempre curiosa y emprendedora, abrió empresas en los segmentos de arte brasileña, consultoría de tecnología, desarrollo de software y alimentación. Después de 32 años trabajando en empresas de alta tecnología, decidió crear la suya propia para poder traducir la tecnología en beneficios efectivos para el negocio, mostrando su aplicación práctica en las operaciones diarias de las empresas.",
      "bioCaique": "Caique Alves es un profesional de tecnología que comenzó su carrera como pasante de desarrollo en 2019. En 2021, se unió al Proyecto Carambola, donde trabajó como desarrollador full stack, manejando tanto el Front-end como el Back-end. En junio del mismo año, Caique fue contratado por Mundo Livre Digital como Desarrollador de Nivel Medio. Trajo consigo su experiencia en desarrollo full stack, convirtiéndose en un activo valioso para la empresa. Caique demostró habilidad y compromiso en su trabajo y, en 2022, fue promovido a Tech Lead, liderando un equipo de desarrollo. Hoy, Caique es responsable de liderar un equipo de desarrolladores, diseñando soluciones tecnológicas innovadoras y asegurando la calidad del trabajo de su equipo.",
      "bioGuilherme": "Ingeniero en Computación y competidor internacional de robótica, Guilherme comenzó su carrera como Desarrollador Full Stack Junior en 2021, el mismo año en que se unió al equipo de Mundo Livre Digital como desarrollador junior. Hoy ocupa el puesto de Tech Lead y lidera un equipo de desarrolladores que diseñan soluciones tecnológicas innovadoras para impulsar el éxito de la empresa. Antes de esto, dedicaba su tiempo a realizar pruebas de penetración en aplicaciones web en busca de Bug Bounty, además de construir y programar robots con lenguajes de bajo nivel, lo que le dio la experiencia para lidiar con arquitecturas de software complejas.",
      "cargoThais": "Gerente Ejecutiva de Humanos",
      "bioThais": "Estudió Psicología en la Universidad Presbiteriana Mackenzie. Trabajó como psicóloga clínica durante 2 años, cambiando su área de enfoque en 2011 después de realizar el curso Six Sigma. A partir de ese momento, el interés por los datos se convirtió en compañero del interés por las personas y sus posibilidades. Trabajó durante muchos años como Analista de Planeación y, en 2018, realizó un MBA en Gestión Estratégica de Negocios, buscando unir sus áreas de interés en sus estudios. En mayo de 2021, comenzó a trabajar en Mundo Livre Digital como responsable de la gestión del área de Business Intelligence (BI). En marzo de 2023, sus objetivos personales y los de la empresa se cruzaron nuevamente, asumiendo entonces la gestión del SH – Gerente Ejecutiva de Humanos.",
      "cargoRenan": "Supervisor de Soporte",
      "bioRenan": "Renan Medeiros, profesional de ingeniería de computación, ingresó al área de tecnología en 2018, como asistente de HelpDesk y Telecom, enfocándose en el soporte tercerizado a empresas. Con el paso de los años y cada vez más entusiasmado con la innovación traída por el área, en julio de 2021 ingresó a Mundo Livre Digital. Comenzó como Analista Jr, pasó por todos los clientes de soporte, como analista Pleno y Senior hasta llegar a la Coordinación de Soporte donde coordina el equipo responsable del análisis, solución y prevención de problemas técnicos, además de liderar iniciativas de mejora continua para agilizar y mejorar el soporte ofrecido a los clientes.",
      "card_humanos_btn": "Más información",


    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage, // Usa o idioma salvo no localStorage
  interpolation: {
    escapeValue: false, // React já faz a sanitização de valores por padrão
  },
});

export default i18n;
