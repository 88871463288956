import React from "react";
import Cabecalho from "../components/PT-BR/Cabecalho";
import Footer from "../components/PT-BR/Footer";
import PoliticyComponente from "../components/PT-BR/politicyComponente";

const PoliticyTermos = () => {
  return (
    <section>
        <Cabecalho />  
        <PoliticyComponente />
        <Footer />
    </section>
  );
};

export default PoliticyTermos;
