// icon:bx-arrow-to-bottom | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

function IconCicleArrowDown(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M6 18h12v2H6zm5-14v8.586L6.707 8.293 5.293 9.707 12 16.414l6.707-6.707-1.414-1.414L13 12.586V4z" />
    </svg>
  );
}

export default IconCicleArrowDown;
