import React from "react";
import "../../styles/PT-BR/especializacaoComponentes.css";
import imagemHomemCelular from "../../img/banner_especializacao.jpg";
import { useTranslation } from "react-i18next"; // Importação do i18n

const EspecializacaoComponentes = () => {

    const { t } = useTranslation(); // Hook do i18n

  return (
    <section>
      <div className="containerEspecializacao">
        <div className="blocoTextoEspecializacao">
          <h1>{t('especializacao_title')}</h1>
          <p className="paragrafo1Especializacao">
            {t('especializacao_paragrafo_1')}
            <br/>
            <br/>
          </p>
          <p className="paragrafo2Especializacao">
          {t('especializacao_paragrafo_2')}
            <br/>
            <br/>
          </p>
          <ul className="listaEspecializacao">
            <li className="listaEspecializacaoItem">
            {t('especializacao_lista_item_1')}
            </li>
            <li className="listaEspecializacaoItem">
            {t('especializacao_lista_item_2')}
            </li>
            <li className="listaEspecializacaoItem">
            {t('especializacao_lista_item_3')}
            </li>
          </ul>
        </div>
        <img className="bannerEspecializacao" src={imagemHomemCelular} />
      </div>
    </section>
  );
};

export default EspecializacaoComponentes;
