import React from "react";
import LogoAws from "../../img/aws-1-e1680724791292.png";
import LogoCarambola from "../../img/carambola.png";
import LogoFreshWorks from "../../img/freshworks-vector-logo.png";
import LogoGoodData from "../../img/gooddata.png";
import LogoGoogleCloud from "../../img/googleClouds.png";
import LogoMedDireta from "../../img/med-direta.png";
import LogoSoulCode from "../../img/soul-code.png";
import LogoSynnex from "../../img/synnex.png";
import LogoTwilio from "../../img/twilio-2-e1680724767562.png";
import "../../styles/PT-BR/parceiros.css";
import { useTranslation } from "react-i18next"; // Importação do i18n

const ParceirosComponentes = () => {

    const { t } = useTranslation(); // Hook do i18n

  return (
    <div className="parceiros_container">
      <div className="parceirosBloco1">
        <h1>{t("parceiros_title")}</h1>
        <p>
         {t("parceiros_paragrafo")}
        </p>
      </div>
      <div className="parceirosBloco2">
        <a className="imagemLogoParceiros" href="https://aws.amazon.com/pt/">
          <img src={LogoAws} alt="Logo AWS" />
        </a>
        <a className="imagemLogoParceiros" href="https://www.carambola.com.vc/">
          <img src={LogoCarambola} alt="Logo Carambola" />
        </a>
        <a className="imagemLogoParceiros" href="https://www.freshworks.com/">
          <img src={LogoFreshWorks} alt="Logo FreshWorks" />
        </a>
        <a className="imagemLogoParceiros" href="https://www.gooddata.com/">
          <img src={LogoGoodData} alt="Logo GoodData" />
        </a>
        <a className="imagemLogoParceiros" href="https://cloud.google.com/">
          <img src={LogoGoogleCloud} alt="Logo Google Clouds" />
        </a>
        <br />
        <a
          className="imagemLogoParceiros"
          href="https://medicinadireta.com.br/"
        >
          <img src={LogoMedDireta} alt="Logo Medicina Direta" />
        </a>
        <a className="imagemLogoParceiros" href="https://soulcode.com/">
          <img src={LogoSoulCode} alt="Logo Soul Code" />
        </a>
        <a className="imagemLogoParceiros" href="https://www.synnexcorp.com/">
          <img src={LogoSynnex} alt="Logo TD SYNNEX" />
        </a>
        <a className="imagemLogoParceiros" href="https://www.twilio.com/pt-br/">
          <img src={LogoTwilio} alt="Logo Twilio" />
        </a>
      </div>
    </div>
  );
};

export default ParceirosComponentes;
