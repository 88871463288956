import React from "react";
import { useMediaQuery } from "@mui/material"; // Importa useMediaQuery
import PersistentDrawerLeft from "../components/PT-BR/menuHamburguer"; // Importa o menuHamburguer
import Cabecalho from "../components/PT-BR/Cabecalho";
import Footer from "../components/PT-BR/Footer";
import MundoCatalogueComponentes from "../components/PT-BR/MundoCatalogueComponentes";


const MundoCatalogue = () => {
  // Define a media query para detectar se é mobile (menor que 960px)
  const isMobile = useMediaQuery("(max-width:960px)");

  return (
    <section>
      {isMobile ? (
        // Renderiza o PersistentDrawerLeft apenas em dispositivos móveis
        <PersistentDrawerLeft />
      ) : (
        // Renderiza o Cabecalho apenas em desktops
        <Cabecalho />
      )}
      <MundoCatalogueComponentes />
      <Footer />
    </section>
  );
};

export default MundoCatalogue;
