// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './languageSlice';

const store = configureStore({
  reducer: {
    language: languageReducer, // Adiciona o slice do idioma ao store
  },
});

export default store;
