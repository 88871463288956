import React, { Suspense, lazy } from "react";
import { useMediaQuery } from "@mui/material"; // Importa useMediaQuery
import '../i18n.js';
import Loading from "../components/Loading.jsx";
import PersistentDrawerLeft from "../components/PT-BR/menuHamburguer.jsx";

const Cabecalho = lazy(() => import("../components/PT-BR/Cabecalho.jsx"));
const Carrossel = lazy(() => import("../components/PT-BR/Carrossel.jsx"));
const Footer = lazy(() => import("../components/PT-BR/Footer.jsx"));

function Home() {
  // Define a media query para detectar se é mobile (menor que 960px)
  const isMobile = useMediaQuery('(max-width:960px)');

  return (
    <section className="container">
      <Suspense fallback={<Loading />}>
        {isMobile ? (
          // Renderiza o PersistentDrawerLeft apenas em dispositivos móveis
          <PersistentDrawerLeft />
        ) : (
          // Renderiza o Cabecalho apenas em desktops
          <Cabecalho />
        )}
        <Carrossel />
        <Footer />
      </Suspense>
    </section>
  );
}

export default Home;
