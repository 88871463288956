import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/PT-BR/mundoDispara.css";
import bannerMundoDispara from "../../img/banner_mundo_dispara.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"; // Importando ícones das setas

const MundoDisparaComponentes = () => {
  const { t } = useTranslation();

  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="sectionMundoDispara">
      <div className="upperBox">
        <div className="boxMundoDispara1">
          <h1 className="titulo_mundo_disp">{t("mundo_dispara_title")}</h1>
          <p className="paragrafo_mundo_disp">{t("mundo_dispara_paragrafo_1")}</p>
        </div>

        <div className="boxMundoDispara2">
          <img
            className="imagemMundoDispara"
            src={bannerMundoDispara}
            alt="Banner MundoDispara"
          />
        </div>
      </div>

      <div className="boxMundoDispara3">
        <div className="box_sub_box">
          <div
            className="sub_box_mundoDispara_1"
            onClick={() => setIsExpanded1(!isExpanded1)}
          >
            <div className="sub_box_titulo">
              <div className="text_icon_md">
                <div className="sub_box_titulo_text">
                  <h2 className="titulo_subbox">{t("MD_telemedicina_title")}</h2>
                </div>
              </div>

              <div className="button_box">
                <div className="sub_box_titulo_icon">
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                </div>
                {isMobile && (
                  <button className="expand-button">
                    <FontAwesomeIcon
                      icon={isExpanded1 ? faChevronDown : faChevronRight}
                    />
                  </button>
                )}
              </div>
            </div>

            <div
              className={`sub_box_body ${
                isExpanded1 || !isMobile ? "expanded" : "collapsed"
              }`}
            >
              <ul className="subbox_list">
                <li>{t("MD_telemedicina_lista_1")}</li>
                <li>{t("MD_telemedicina_lista_2")}</li>
                <li>{t("MD_telemedicina_lista_3")}</li>
                <li>{t("MD_telemedicina_lista_4")}</li>
              </ul>
            </div>
          </div>

          <div
            className="sub_box_mundoDispara_2"
            onClick={() => setIsExpanded2(!isExpanded2)}
          >
            <div className="sub_box_titulo">
              <div className="text_icon_md">
                <div className="sub_box_titulo_text">
                  <h2 className="titulo_subbox">{t("MD_operadoraSaude_title")}</h2>
                </div>
              </div>
              <div className="button_box">
                <div className="sub_box_titulo_icon">
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                </div>
                {isMobile && (
                  <button className="expand-button">
                    <FontAwesomeIcon
                      icon={isExpanded2 ? faChevronDown : faChevronRight}
                    />
                  </button>
                )}
              </div>
            </div>

            <div
              className={`sub_box_body ${
                isExpanded2 || !isMobile ? "expanded" : "collapsed"
              }`}
            >
              <ul className="subbox_list">
                <li>{t("MD_operadoraSaude_lista_1")}</li>
                <li>{t("MD_operadoraSaude_lista_2")}</li>
                <li>{t("MD_operadoraSaude_lista_3")}</li>
                <li>{t("MD_operadoraSaude_lista_4")}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="box_sub_box">
          <div
            className="sub_box_mundoDispara_3"
            onClick={() => setIsExpanded3(!isExpanded3)}
          >
            <div className="sub_box_titulo">
              <div className="text_icon_md">
                <div className="sub_box_titulo_text">
                  <h2 className="titulo_subbox">{t("MD_hospital_title")}</h2>
                </div>
              </div>
              <div className="button_box">
                <div className="sub_box_titulo_icon">
                  <FontAwesomeIcon icon={faWhatsapp} className="icon" />
                </div>
                {isMobile && (
                  <button className="expand-button">
                    <FontAwesomeIcon
                      icon={isExpanded3 ? faChevronDown : faChevronRight}
                    />
                  </button>
                )}
              </div>
            </div>

            <div
              className={`sub_box_body ${
                isExpanded3 || !isMobile ? "expanded" : "collapsed"
              }`}
            >
              <ul className="subbox_list">
                <li>{t("MD_hospital_lista_1")}</li>
                <li>{t("MD_hospital_lista_2")}</li>
                <li>{t("MD_hospital_lista_3")}</li>
              </ul>
            </div>
          </div>

          <div
            className="sub_box_mundoDispara_4"
            onClick={() => setIsExpanded4(!isExpanded4)}
          >
            <div className="sub_box_titulo">
              <div className="text_icon_md">
                <div className="sub_box_titulo_text">
                  <h2 className="titulo_subbox">{t("MD_marketplace_title")}</h2>
                </div>
              </div>
              <div className="button_box">
                <div className="sub_box_titulo_icon">
                  <FontAwesomeIcon icon={faWhatsapp} className="icon" />
                </div>
                {isMobile && (
                  <button className="expand-button">
                    <FontAwesomeIcon
                      icon={isExpanded4 ? faChevronDown : faChevronRight}
                    />
                  </button>
                )}
              </div>
            </div>

            <div
              className={`sub_box_body ${
                isExpanded4 || !isMobile ? "expanded" : "collapsed"
              }`}
            >
              <ul className="subbox_list">
                <li>{t("MD_marketplace_lista_1")}</li>
                <li>{t("MD_marketplace_lista_2")}</li>
                <li>{t("MD_marketplace_lista_3")}</li>
                <li>{t("MD_marketplace_lista_4")}</li>
                <li>{t("MD_marketplace_lista_5")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MundoDisparaComponentes;
