import React, { useState } from "react";
import "../../styles/PT-BR/contato.css";
import { useTranslation } from "react-i18next"; // Importação do i18n

const ContatoComponentes = () => {
  const { t } = useTranslation(); // Hook do i18n

  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    consentimento: false
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Dados do formulário:", formData);
    setIsModalOpen(false); // Fecha o pop-up após o envio
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="containerContato">
      <div className="box1contato">
        <h1 className="titulo_contato">{t("contatoTitulo")}</h1>

        {/* Seção de redes sociais */}
        <div>
          <p className="item_contato">Instagram</p>
          <h2>
            <a
              className="contatosLink"
              href="https://instagram.com/mundolivredigital"
              target="_blank"
              rel="noopener noreferrer"
            >
              @mundolivredigital
            </a>
          </h2>
        </div>

        <div>
          <p className="item_contato">LinkedIn</p>
          <h2>
            <a
              className="contatosLink"
              href="https://www.linkedin.com/company/mundolivredigital"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mundo Livre Digital
            </a>
          </h2>
        </div>

        <div>
          <p className="item_contato">{t("contatoTelefone")}</p>
          <h2>
            <a className="contatosLink" href="tel:+551149330355">
              +55(11)4933-0355
            </a>
          </h2>
        </div>

        <div>
          <p className="item_contato">E-mail</p>
          <h2>
            <a className="contatosLink" href="mailto:contato@mundolivre.digital">
              contato@mundolivre.digital
            </a>
          </h2>
        </div>

        <button className="contato_button" onClick={handleOpenModal}>{t("botao_form")}</button>

        {isModalOpen && (
          <div className="modal" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close" onClick={handleCloseModal}>&times;</span>
              <h2>{t("titulo_form")}</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="nome">{t("nome_form")}</label>
                  <input
                    type="text"
                    id="nome"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">{t("email_form")}</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="telefone">{t("telefone_form")}</label>
                  <input
                    type="tel"
                    id="telefone"
                    name="telefone"
                    value={formData.telefone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="terms-message">
                  <p className="terms-text">
                    {t("termo_servico_1")}
                    <a href="/termos"  target="_blank" rel="noopener noreferrer"> {t("termo_servico_2")}</a> {t("termo_servico_3")}
                    <a href="/politicas-de-privacidade" target="_blank" rel="noopener noreferrer"> {t("termo_servico_4")}</a>.
                  </p>
                  <div className="checkbox-container">
                    <input type="checkbox" id="sms-consent" name="smsConsent" required />
                    <label htmlFor="sms-consent">
                     {t("autorizacao_envio_messagem")}
                    </label>
                  </div>
                </div>
                <button className="submit_button" type="submit">{t("enviar_form")}</button>
              </form>
            </div>
          </div>
        )}
      </div>

      <div className="box2contato">
        <iframe
          title="mapa"
          className="mapa_contato"
          src={t("mapaMLD")}
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContatoComponentes;
