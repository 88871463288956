import React from "react";
import Cabecalho from "../components/PT-BR/Cabecalho";
import Footer from "../components/PT-BR/Footer";
import TermosComponentes from "../components/PT-BR/termosComponentes";

const PoliticaTermos = () => {
  return (
    <section>
        <Cabecalho />  
        <TermosComponentes />
        <Footer />
    </section>
  );
};

export default PoliticaTermos;
