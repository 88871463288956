import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../styles/PT-BR/politicas.css";

const PoliticaComponentes = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const source = params.get("source");

  // Estado inicial baseado no "source"
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    if (source === "privacidade") {
      setExpandedSection("privacidade");
    } else if (source === "termos") {
      setExpandedSection("termos");
    }
  }, [source]);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (

    <div className="politicasContainer">
      <h1 className="tituloPolitica">{t("titulo_principal_politicas")}</h1>

      <div className="dropdownSection">
        <div
          className="dropdownHeader"
          onClick={() => toggleSection("privacidade")}
        >
          <h2>{t("titulo_dropdown_politicas")}</h2>
        </div>
        <div
          className={`dropdownContent ${expandedSection === "privacidade" ? "expanded" : "collapsed"
            }`}
        >
          <div className="drop-politicas">
            <h2 className="titulo2Politica">{t("politicas_titulo_1")}</h2>
          <p className="paragrafoPolitica">
           {t("paragrafo_politicas_1")}
          </p>
          <h2 className="titulo2Politica">{t("politicas_titulo_2")}</h2>
          <p className="paragrafoPolitica">
           {t("paragrafo_politicas_2")}
          </p>
          <h2 className="titulo2Politica">{t("politicas_titulo_3")}</h2>
          <p className="paragrafoPolitica">
            {t("paragrafo_politicas_3_1")}
          </p>
          <br />
          <p className="paragrafoPolitica">
            {t("paragrafo_politicas_3_2")}
          </p>
          <br />
          <p className="paragrafoPolitica">
            {t("paragrafo_politicas_3_3")}
          </p>
          <br />
          
          <ul className="listaPolitica">
            <p className="paragrafoPolitica">
              {t("paragrafo_politicas_3_4")}
            </p>
            <li className="itemPolitica">{t("lista_politicas_3_1")}</li>
            <li className="itemPolitica">{t("lista_politicas_3_2")}</li>
            <li className="itemPolitica">{t("lista_politicas_3_3")}</li>
          </ul>
          <br />
          <p className="paragrafoPolitica">
              {t("paragrafo_politicas_3_5_1")} <span><a className="data-email" href="mailto:dataprotection@mundolivre.digital">
               dataprotection@mundolivre.digital
            </a></span>, {t("paragrafo_politicas_3_5_2")}
          </p>
          <br />
          <p className="paragrafoPolitica">
             {t("paragrafo_politicas_3_6")}
          </p>
          <ul className="listaPolitica">
            <h2 className="titulo2Politica">{t("politicas_titulo_4")}</h2>
            <p className="paragrafoPolitica">
              {t("paragrafo_politicas_4")}
            </p>
            <li className="itemPolitica">
              {t("lista_politicas_4_1")}
            </li>
            <li className="itemPolitica">
              {t("lista_politicas_4_2")}
            </li>
            <li className="itemPolitica">
              {t("lista_politicas_4_3")}
            </li>
            <li className="itemPolitica">
              {t("lista_politicas_4_4")}
            </li>
            <li className="itemPolitica">
              {t("lista_politicas_4_5")}
            </li>
            <li className="itemPolitica">
              {t("lista_politicas_4_6")}
            </li>
            <li className="itemPolitica">
              {t("lista_politicas_4_7")}
            </li>
          </ul>
          <h2 className="titulo2Politica">{t("politicas_titulo_5")}</h2>
          <p className="paragrafoPolitica">
               {t("paragrafo_politicas_5_1")} <span><a className="data-email" href="mailto:dataprotection@mundolivre.digital">
               dataprotection@mundolivre.digital
            </a></span>, {t("paragrafo_politicas_5_2")}
            </p>
          <h2 className="titulo2Politica">
            {t("politicas_titulo_6")}
          </h2>
          <p className="paragrafoPolitica">{t("paragrafo_politicas_6_1")}</p>
          <p className="paragrafoPolitica">{t("paragrafo_politicas_6_2")}</p>
        </div>
        <br/>
        <Link to="/contato" className="linkPolitica">
         {t("link_pagina_contato_politicas")}
        </Link>
      </div>
             </div>
      <div className="dropdownSection">
        <div className="dropdownHeader" onClick={() => toggleSection("termos")}>
          <h2>{t("titulo_dropdown_termos")}</h2>
        </div>
        <div
          className={`dropdownContent ${expandedSection === "termos" ? "expanded" : "collapsed"
            }`}
        >
          <div className="drop-termos">
          <p className="paragrafo-termos">{t("paragrafo_termos_1")}</p>
          <br />
          <p className="paragrafo-termos">{t("paragrafo_termos_2")}</p>
          <br />
          <p className="paragrafo-termos">{t("paragrafo_termos_3")}</p>
          <br />
          <p className="paragrafo-termos">{t("paragrafo_termos_4")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoliticaComponentes;
