import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PT from '../../img/pt-br.svg';
import EN from '../../img/en.svg';
import ES from '../../img/es.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'; // Importa o dispatch e o selector do Redux
import { setLanguage } from '../../redux/languageSlice'; // Importa a action para alterar o idioma
import { useEffect } from 'react';

export default function BasicSelect() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  // Inicialize o estado do Redux a partir do localStorage ou use 'pt' como padrão
  const language = useSelector((state) => state.language) || localStorage.getItem('selectedLanguage') || 'pt';

  // Sincroniza o idioma ao montar o componente
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage') || 'pt';
    
    // Atualiza o Redux se o idioma no localStorage for diferente do estado atual
    if (language !== savedLanguage) {
      dispatch(setLanguage(savedLanguage)); // Atualiza o Redux
    }
    
    // Atualiza o i18n para garantir que o idioma seja consistente
    i18n.changeLanguage(savedLanguage);
    
  }, [dispatch, i18n, language]);

  const handleChange = (event) => {
    const selectedLanguage = event.target.value;
    
    // Altera o estado global com Redux
    dispatch(setLanguage(selectedLanguage));
    
    // Altera o idioma do i18n
    i18n.changeLanguage(selectedLanguage);
    
    // Salva o idioma selecionado no localStorage
    localStorage.setItem('selectedLanguage', selectedLanguage);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Language</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language} // Pega o valor do idioma do Redux ou localStorage
          label="Language"
          onChange={handleChange}
          sx={{
            height: '35px',
            width: '120px',
            '& fieldset': {
              borderColor: '#000',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E80088',
            },
            fontSize: '12px',
          }}
        >
          <MenuItem value="pt">
            <Box display="flex" alignItems="center">
              <img src={PT} alt="Português" width="20" style={{ marginRight: '8px' }} />
              PT
            </Box>
          </MenuItem>
          <MenuItem value="en">
            <Box display="flex" alignItems="center">
              <img src={EN} alt="Inglês" width="20" style={{ marginRight: '8px' }} />
              EN
            </Box>
          </MenuItem>
          <MenuItem value="es">
            <Box display="flex" alignItems="center">
              <img src={ES} alt="Espanhol" width="20" style={{ marginRight: '8px' }} />
              ES
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
