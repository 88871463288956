import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  Route,
  RouterProvider
} from "react-router-dom";

import { Provider } from 'react-redux'; // Importa o Provider do Redux
import store from '../src/redux/store'; // Importa o store configurado

import Home from "./routes/home";
import SobreNos from "./routes/sobre-nos";
import Humanos from './routes/humanos';
import Especializacao from './routes/especializacao';
import Parceiros from './routes/parceiros';
import Clientes from './routes/clientes';
import Mundoagenda from './routes/mundoagenda';
import MundoAtende from './routes/mundoAtende';
import MundoDispara from './routes/mundoDispara';
import MundoAcademy from './routes/mundoAcademy';
import MundoCatalogue from './routes/mundoCatalogue';
import Contato from './routes/contato';
import Politica_de_privacidade from './routes/politica-de-privacidade';
import PoliticaTermos from './routes/politica-de-termos';
import PoliticyTermos from './routes/politicy-de-privacidade';

// Define as rotas do projeto
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/sobre-nos",
    element: <SobreNos />
  },
  {
    path: "/humanos",
    element: <Humanos />
  },
  {
    path: "/especializacao",
    element: <Especializacao />
  },
  {
    path: "/parceiros",
    element: <Parceiros />
  },
  {
    path: "/clientes",
    element: <Clientes />
  },
  {
    path: "/mundoagenda",
    element: <Mundoagenda />
  },
  {
    path: "/mundoatende",
    element: <MundoAtende />
  },
  {
    path: "/mundodispara",
    element: <MundoDispara />
  },
  {
    path: "/mundoacademy",
    element: <MundoAcademy />
  },
  {
    path: "/mundocatalogue",
    element: <MundoCatalogue />
  },
  {
    path: "/contato",
    element: <Contato />
  },
  {
    path: "/politicas-e-termos",
    element: <Politica_de_privacidade/>
  },
  {
    path: "/termos",
    element: <PoliticaTermos/>
  },
  {
    path: "/politicas-de-privacidade",
    element: <PoliticyTermos/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Envolve a aplicação com o Provider do Redux */}
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
