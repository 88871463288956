import React from "react";
import bannerMulher from "../../img/banner_home_2-978x1024.jpg";
import bannerSobreNos from "../../img/banner_sobre_nos-1024x590.jpg";
import "../../styles/PT-BR/sobre-nos-componentes.css";
import { useTranslation } from "react-i18next"; // Importação do i18n

const SobreNosComponentes = () => {
  const { t } = useTranslation(); // Hook do i18n

  return (
    <section className="container">
      <div className="upContainer">
        <div className="paragrafo1">
          <h1>
            <strong>{t('once_upon_a_time_title')}</strong> {/* Tradução do título */}
          </h1>
          <p>
            {t('once_upon_a_time_paragraph_1')}
            <br/>
            <br/>
            {t('once_upon_a_time_paragraph_2')}
            <br/>
            <br/>
            {t('once_upon_a_time_paragraph_3')}
            <br/>
            <br/>
            {t('once_upon_a_time_paragraph_4')}
             {/* Tradução do parágrafo */}
          </p>
        </div>
        <img
          className="imagemMulher-sobrenos"
          src={bannerMulher}
          alt={t('woman_alt_text')} /* Tradução do texto alternativo da imagem */
        />
      </div>
      <div className="downContainer">
        <img
          className="imagem-sobrenos"
          src={bannerSobreNos}
          alt={t('group_people_alt_text')} /* Tradução do texto alternativo da imagem */
        />
        <div className="paragrafo2">
          <p className="italico">
            {t('wilson_quote')} {/* Tradução da citação */}
          </p>
          <p>– DR. EDWARD OSBORNE WILSON, SOCIOBIOLOGIST</p>
        </div>
      </div>
    </section>
  );
};

export default SobreNosComponentes;
