import React, { useState, useEffect, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WorkIcon from '@mui/icons-material/Work';
import AddIcon from '@mui/icons-material/Add'; // Ícone de "+"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

import logoMLD from "../../img/logo-mundodigital.png";
import BasicSelect from './selectLanguage';
import '../../styles/PT-BR/menu-hamburguer.css';

const drawerWidth = 240;

// const Main = styled('main')(({ theme }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(3),
//   transition: theme.transitions.create('margin', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: 0,
//   maxWidth: '100vw',
//   overflow: 'hidden',
// }));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: '#fff',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const SelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  padding: theme.spacing(2),
  position: 'relative',
  height: 'auto',
  maxWidth: '100%',
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState({ expertize: false, mundoFaz: false });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleMenuClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setSubmenuOpen({ ...submenuOpen, [menu]: !submenuOpen[menu] });
  };

  const handleMenuClose = (menu) => {
    setAnchorEl(null);
    setSubmenuOpen({ ...submenuOpen, [menu]: false });
  };


  // Função para detectar cliques fora do Drawer
  const handleClickOutside = useCallback((event) => {
  const drawer = document.getElementById('drawer');
  if (drawer && !drawer.contains(event.target)) {
    handleDrawerClose();
  } else {
    setOpen(true);
  }
}, [handleDrawerClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Logo na esquerda */}
          <a href="https://www.mundolivre.digital/">
            <img src={logoMLD} alt="Logo" style={{ height: '40px' }} />
          </a>
          {/* Ícone do menu hamburguer na direita */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="end"
            sx={{ color: 'black' }} // Ícone preto
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        id="drawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            right: 0,
            left: 'auto',
            maxWidth: '100vw',
          },
        }}
        variant="persistent"
        anchor="right"
        open={open} // O drawer abre pela direita
        
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="HOME" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton aria-controls="sobre-nos-menu" aria-haspopup="true" onClick={(e) => handleMenuClick(e, 'expertize')}>
              <ListItemIcon>
                <AddIcon /> {/* Ícone de "+" para indicar dropdown */}
              </ListItemIcon>
              <ListItemText primary="SOBRE NÓS" />
            </ListItemButton>
          </ListItem>
          <Menu
            id="sobre-nos-menu"
            anchorEl={anchorEl}
            open={Boolean(submenuOpen.expertize)}
            onClose={() => handleMenuClose('expertize')}
          >
            <MenuItem component={Link} to="/sobre-nos" onClick={handleMenuClose}>ERA UMA VEZ</MenuItem>
            <MenuItem component={Link} to="/humanos" onClick={handleMenuClose}>HUMANOS</MenuItem>
            <MenuItem component={Link} to="/especializacao" onClick={() => handleMenuClose('expertize')}>ESPECIALIZAÇÃO</MenuItem>
            <MenuItem component={Link} to="/parceiros" onClick={() => handleMenuClose('expertize')}>PARCEIROS</MenuItem>
            <MenuItem component={Link} to="/clientes" onClick={handleMenuClose}>CLIENTES</MenuItem>
          </Menu>
          <ListItem disablePadding>
            <ListItemButton aria-controls="mundo-faz-menu" aria-haspopup="true" onClick={(e) => handleMenuClick(e, 'mundoFaz')}>
              <ListItemIcon>
                <AddIcon /> {/* Ícone de "+" para indicar dropdown */}
              </ListItemIcon>
              <ListItemText primary="MundoFaz" />
            </ListItemButton>
          </ListItem>
          <Menu
            id="mundo-faz-menu"
            anchorEl={anchorEl}
            open={Boolean(submenuOpen.mundoFaz)}
            onClose={() => handleMenuClose('mundoFaz')}
          >
            <MenuItem component={Link} to="/mundoagenda" onClick={() => handleMenuClose('mundoFaz')}>MundoAgenda</MenuItem>
            <MenuItem component={Link} to="/mundoatende" onClick={() => handleMenuClose('mundoFaz')}>MundoAtende</MenuItem>
            <MenuItem component={Link} to="/mundodispara" onClick={() => handleMenuClose('mundoFaz')}>MundoDispara</MenuItem>
            <MenuItem component={Link} to="/mundoacademy" onClick={() => handleMenuClose('mundoFaz')}>MundoAcademy</MenuItem>
            <MenuItem component={Link} to="/mundocatalogue" onClick={() => handleMenuClose('mundoFaz')}>MundoCatalogue</MenuItem>
          </Menu>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/contato">
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary="CONTATO" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="https://www.reachr.io" target="_blank" rel="noopener noreferrer">
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="TRABALHE CONOSCO" />
            </ListItemButton>
          </ListItem>
        </List>
        <SelectContainer>
          <BasicSelect />
        </SelectContainer>
      </Drawer>
    </Box>
  );
}
