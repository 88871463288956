import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../styles/PT-BR/politicas.css";

const TermosComponentes = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="termosdiv">
        <h2 className="tituloTermos">{t("titulo_dropdown_termos")}</h2>
        <p className="termos">{t("paragrafo_termos_1")}</p>
        <br />
        <p className="termos">{t("paragrafo_termos_2")}</p>
        <br />
        <p className="termos">{t("paragrafo_termos_3")}</p>
        <br />
        <p className="termos">{t("paragrafo_termos_4")}</p>
      </div>
    </div>

  )
};

export default TermosComponentes;
