import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf'; // Importa os componentes do react-pdf
import { useTranslation } from "react-i18next"; // Importação do i18n
import pdfMundoAcademy from "../../assets/MundoAcademy-1.pdf";
import IconCicleArrowDown from "../page-icons/IconCicleArrowDown"; // Importa ícone do download
import "../../styles/PT-BR/mundoAcademy.css";
import "../../styles/PT-BR/smoothTransition.css"; // Importa o arquivo CSS para animações

// Configura o worker do pdfjs para o react-pdf usando o worker local
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const MundoAcademyComponentes = () => {
  const { t } = useTranslation(); // Hook do i18n

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(window.innerWidth * 0.9);
  const [fade, setFade] = useState(true); // Estado para controlar a transição de fade
  const [pagesCache, setPagesCache] = useState({}); // Cache de páginas carregadas

  useEffect(() => {
    const updateWidth = () => {
      const newWidth = window.innerWidth * 0.9;
      setPageWidth(newWidth);
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPagesCache({ 1: true }); // Carrega a primeira página por padrão no cache
  };

  const handlePageChange = (newPageNumber) => {
    setFade(false); // Inicia a transição de fade out
    setTimeout(() => {
      setPageNumber(newPageNumber);
      setPagesCache(prevCache => ({
        ...prevCache,
        [newPageNumber]: true, // Salva a nova página no cache
      }));
      setFade(true); // Reativa a transição de fade in após o carregamento da nova página
    }, 300); // Define o tempo da animação
  };

  const handleDownload = () => {
    // Cria um link temporário para baixar o PDF
    const link = document.createElement('a');
    link.href = pdfMundoAcademy;
    link.setAttribute('download', 'MundoAcademy.pdf'); // Define o nome do arquivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderPage = (pageNum) => {
    // Garante que a primeira página sempre seja carregada
    if (pagesCache[pageNum] || pageNum === 1) {
      return (
        <div className="pdfPageWrapper">
          <Page
            key={`page_${pageNum}`}
            pageNumber={pageNum}
            width={Math.min(pageWidth, 800)}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
          {/* Adiciona a seta de download do Bootstrap */}
          <div className="download-arrow" onClick={handleDownload}>
            <IconCicleArrowDown />
            <span className="tooltip">Download</span>
          </div>
        </div>
      );
    }else {
      return (
        <div className="loadingPlaceholder">Loading PDF...</div>
      );
    }
  };

  return (
    <div className='pdfContainer'>
      <Document
        file={pdfMundoAcademy}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <div className={`pdfPageWrapper ${fade ? 'fade-in' : 'fade-out'}`}>
          {renderPage(pageNumber)}
        </div>
      </Document>

      <div className='pagination'>
        <p>{t("pagina")} {pageNumber} {t("de")} {numPages}</p>
        <button
          type="button"
          onClick={() => handlePageChange(Math.max(pageNumber - 1, 1))}
          disabled={pageNumber === 1}
        >
          {t("botao_prev")}
        </button>
        <button
          type="button"
          onClick={() => handlePageChange(Math.min(pageNumber + 1, numPages))}
          disabled={pageNumber === numPages}
        >
          {t("botão_next")}
        </button>
      </div>
    </div>
  );
};


export default MundoAcademyComponentes;
