// components/Loading.js
import React, { useEffect, useState } from "react";
import "../styles/PT-BR/loading.css";
import LogoMld from "../img/logo-mundodigital.png";

function Loading() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true); // Mostra o componente após 2 segundos
    }, 2000); // 2 segundos de atraso

    return () => clearTimeout(timer); // Limpa o timer se o componente for desmontado antes do tempo
  }, []);

  return (
    <div className={`loading ${show ? 'show' : ''}`}>
      <img className="loading-img" src={LogoMld} alt="Logo"/>
      <p className="loading-p">Loading...</p>
    </div>
  );
}

export default Loading;
