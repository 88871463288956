import React from "react";
import "../../styles/PT-BR/clientesComponentes.css";
import { useTranslation } from "react-i18next";

//imagens-------

import logoGuerini from "../../img/Logo-Guerini-Horizontal-Sem-Fundo.png";
import logoPrevent from "../../img/prevent-senior-logo.png";
import logoDasa from "../../img/dasa.png";
import logoElux from "../../img/Electrolux-Logo.png";
import logoLBV from "../../img/logo_lbv_horizontal-1024x416.png";

//--------------

const ClientesComponentes = () => {
  const { t } = useTranslation();
  return (
    <div className="containerClientes">
      <div className="blocoTextoClientes">
      <h1>
      {t("clientes_title")}
      </h1>
      <p>{t("clientes_paragrafo")}</p>
      </div>
    <div className="image-container">
      <div className="image-item">
        <a href="https://portal.guerini.com.br/" target="_blank" rel="noopener noreferrer">
        <img src={logoGuerini} alt="Guerini" />
        </a>
      </div>
      <div className="image-item">
        <a href="https://www.preventsenior.com.br/" target="_blank" rel="noopener noreferrer">
        <img src={logoPrevent} alt="Prevent Senior" />
        </a>
      </div>
      <div className="image-item">
      <a href="https://dasa.com.br/" target="_blank" rel="noopener noreferrer">
        <img src={logoDasa} alt="Dasa" />
        </a>
      </div>
      {/* <div className="image-item">
        <a href="https://cuida.electrolux.com.br/" target="_blank" rel="noopener noreferrer">
        <img src={logoElux} alt="Electrolux" />
        </a>
      </div>
      <div className="image-item">
        <a href="https://lbv.org/" target="_blank" rel="noopener noreferrer">
        <img src={logoLBV} alt="LBV" />
        </a>
      </div> */}
    </div>
    </div>
  );
};

export default ClientesComponentes;
