import React from "react";
import { useTranslation } from "react-i18next";
import "../../styles/PT-BR/mundoAtendeComponentes.css";
import bannerMundoAtende from "../../img/banner_mundo_atende.jpg";

const MundoAtendeComponentes = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="containerMundoAtende">
        <div className="box1mundoAtende">
          <h1>{t("mundo_atende_title")}</h1>
          <h2>{t("mundo_atende_subtitle")}</h2>
          <p>{t("mundo_atende_description1")}</p>
          <h2>{t("mundo_atende_how_it_works")}</h2>
          <p>{t("mundo_atende_description2")}</p>
          <p>{t("mundo_atende_description3")}</p>
          <p>{t("mundo_atende_description4")}</p>
          <p>{t("mundo_atende_description5")}</p>
        </div>
        <div className="box2mundoAtende">
          <img
            src={bannerMundoAtende}
            className="imagemMundoAtende"
            alt={t("mundo_atende_image_alt")}
          />
        </div>
      </div>
    </section>
  );
};

export default MundoAtendeComponentes;
