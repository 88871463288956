import React from "react";
import bannerMundoAgenda from "../../img/banner_mundo_agenda.jpg";
import "../../styles/PT-BR/mundoAgendaComponentes.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const MundoAgendaComponentes = () => {
  const { t } = useTranslation();
  return (
    <div className="containerMundoAgenda">
      <div className="box1mundoAgenda">
        <h1>{t("mundo_agenda_title")}</h1>
        <h2>
        {t("mundo_agenda_subtitle")}
        </h2>
        <p>
        {t("mundo_agenda_paragrafo_1")}
        </p>
        <p>
        {t("mundo_agenda_paragrafo_2")}
        </p>
        <a className="mundoAgendaLink" href="https://mundoagenda.com.br/" >
        {t("mundo_agenda_link")}
        {/* <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon"/> */}
        </a>
      </div>
      <div className="box2mundoAgenda">
        <img
          src={bannerMundoAgenda}
          className="imagemMundoAgenda"
          alt="Banner mundo agenda"
        />
      </div>
    </div>
  );
};

export default MundoAgendaComponentes;
