import React from "react";
import "../../styles/PT-BR/cabecalho.css";
import logo from "../../img/logo-mundodigital.png";
import BasicSelect from "../../components/PT-BR/selectLanguage";
import { useTranslation } from "react-i18next"; // Importe o hook de tradução
import { Link } from "react-router-dom"; // Importe o Link do react-router-dom

function Cabecalho() {
  const { t } = useTranslation(); // Use o hook para acessar a função de tradução

  // Defina URLs para o idioma padrão
  const urls = {
    home: "/",
    aboutUs: "/sobre-nos/",
    contact: "/contato/",
    humanos: "/humanos/",
    especializacao: "/especializacao/",
    parceiros: "/parceiros/",
    clientes: "/clientes/",
    mundoagenda: "/mundoagenda/",
    mundoatende: "/mundoatende/",
    mundodispara: "/mundodispara/",
    mundoacademy: "/mundoacademy/",
    mundocatalogue: "/mundocatalogue/"
  };

  return (
    <header className="cabecalho">
      <Link to={urls.home}>
        <img className="logo-mld" src={logo} alt="Logo MLD" />
      </Link>
      <ul className="cabecalho-lista">
        <li className="cabecalho-lista-item">
          <Link className="cabecalho-lista-item-link" to={urls.home}>
            {t('home')}
          </Link>
        </li>
        <li className="cabecalho-lista-item dropdown">
          <Link className="cabecalho-lista-item-link" to="#">
            {t('about_us')}
          </Link>
          <ul className="dropdown-content">
            <li>
              <Link className="cabecalho-lista-item-link" to={urls.aboutUs}>
                {t('once_upon_a_time')}
              </Link>
            </li>
            <li>
              <Link className="cabecalho-lista-item-link" to={urls.humanos}>
                {t('humans')}
              </Link>
            </li>
            <li className="cabecalho-lista-item expertize">
              <Link className="cabecalho-lista-item-link" to="#">
                {t('expertise')}
              </Link>
              <ul className="expertizeContent">
                <li className="cabecalho-lista-item">
                  <Link to={urls.especializacao} className="cabecalho-lista-item-link">
                    {t('specialization')}
                  </Link>
                </li>
                <li className="cabecalho-lista-item">
                  <Link to={urls.parceiros} className="cabecalho-lista-item-link">
                    {t('partners')}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="cabecalho-lista-item-link" to={urls.clientes}>
                {t('clients')}
              </Link>
            </li>
            <li className="cabecalho-lista-item mundofaz">
              <Link className="cabecalho-lista-item-link" to="#">
                {t('mundo_faz')}
              </Link>
              <ul className="mundofazContent">
                <li>
                  <Link className="cabecalho-lista-item-link" to={urls.mundoagenda}>
                    {t('mundo_agenda')}
                  </Link>
                </li>
                <li>
                  <Link className="cabecalho-lista-item-link" to={urls.mundoatende}>
                    {t('mundo_atende')}
                  </Link>
                </li>
                <li>
                  <Link className="cabecalho-lista-item-link" to={urls.mundodispara}>
                    {t('mundo_dispara')}
                  </Link>
                </li>
                <li>
                  <Link className="cabecalho-lista-item-link" to={urls.mundoacademy}>
                    {t('mundo_academy')}
                  </Link>
                </li>
                <li>
                  <Link className="cabecalho-lista-item-link" to={urls.mundocatalogue}>
                    {t('mundo_catalogue')}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="cabecalho-lista-item">
          <Link className="cabecalho-lista-item-link" to={urls.contact}>
            {t('contact')}
          </Link>
        </li>
        <li className="cabecalho-lista-item">
          <Link className="cabecalho-lista-item-link" to="https://www.reachr.com.br/mundolivre/">
            {t('work_with_us')}
          </Link>
        </li>
        <li className="cabecalho-lista-item">
          <BasicSelect className="lista-idioma" />
        </li>
      </ul>
    </header>
  );
}

export default Cabecalho;
