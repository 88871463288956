import React from "react";
import Cabecalho from "../components/PT-BR/Cabecalho";
import Footer from "../components/PT-BR/Footer";
import PoliticaComponentes from "../components/PT-BR/politicaComponentes";

const Politica_de_privacidade = () => {
  return (
    <section>
        <Cabecalho />  
        <PoliticaComponentes />
        <Footer />
    </section>
  );
};

export default Politica_de_privacidade;
